import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import ReactGA from 'react-ga4';
import getEnv from "tools/getEnv";

const GA4 = getEnv("REACT_APP_GA4")
ReactGA.initialize(GA4);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
