export const getLocalStorage = (name: string) => {
  const value = localStorage.getItem(name);
  if (!value) {
    return null;
  }
  return JSON.parse(localStorage.getItem(name) || "");
};

export const setLocalStorage = (key: string, value: any) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
