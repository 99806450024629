import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface RefreshTokenParameters {
  refreshToken: string;
}

export interface RefreshTokenResponseType extends QueryResponse {
  payload: {
    token: string;
    refreshToken: string;
  };
}

export const refreshToken = async (
  axios: AxiosInstance,
  params: RefreshTokenParameters
): Promise<RefreshTokenResponseType> => {
  try {
    const { refreshToken } = params;

    let requestBody = {
      refresh_token: refreshToken,
    };
    const response = await axios.post<RefreshTokenResponseType>(
      `/users/refresh`,
      JSON.stringify(requestBody)
    );
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: { token: "", refreshToken: "" },
        result: "failed",
      };
    }
    throw error;
  }
};
