import React, { useState } from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import TopContainer from "container/TopContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { validatePassword } from "tools/validate";
import { PasswordTip } from "container/SignUpPage";
import Popup from "components/Popup";
import { triggerOpenModal } from "redux/modalLoading/uiSlice";
import api from "api";
import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);
const NewPasswordPage: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [openPasswordErrorModal, setOpenPasswordErrorModal] =
    useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    registerInfo: { resetToken },
    uiInfo: { openModal },
  } = useSelector((state: RootState) => state);
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content", "au-su")}>
          <div className={cx("insert-new-password-wrap")}>
            <h1>Setting New Password</h1>
            <div className={cx("password-column")}>
              <input
                type="password"
                placeholder="Setting your new password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Insert your new password again"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                onClick={async () => {
                  setErrorMsg("");
                  if (newPassword.length === 0) {
                    setErrorMsg("Password should not be empty!");
                    return;
                  }
                  if (confirmPassword.length === 0) {
                    setErrorMsg("Insert your new password again, please.");
                  }
                  if (confirmPassword !== newPassword) {
                    setErrorMsg("Passwords should be the same.");
                    return;
                  }
  
                  if (!validatePassword(newPassword)) {
                    dispatch(triggerOpenModal(true));
                    setOpenPasswordErrorModal(true);
                    return;
                  }
                  try {
                    const res = await api.resetPasswordWithResetToken({
                      resetToken,
                      newPassword,
                      confirmPassword,
                    });
                    if (res.status.toString() === "0") {
                      dispatch(triggerOpenModal(true));
                      setOpenSuccessModal(true);
                    }
                  } catch (error) {
                    if (isAxiosError(error)) {
                      if (error.response && error.response.data.message) {
                        setErrorMsg(error.response.data.message);
                      }
                    }
                  }
                }}
              >
                Submit
              </button>
              <div className={cx("error-msg")}>{errorMsg}</div>
            </div>
          </div>
        </div>
        {openModal && openPasswordErrorModal && (
          <Popup
            title="Password must have:"
            content={<PasswordTip />}
            buttonName="ok"
            action={() => {
              dispatch(triggerOpenModal(false));
            }}
          />
        )}
        {openSuccessModal && openModal && (
          <Popup
            title="Reset Password Success"
            content="You can login by new password now."
            buttonName="ok"
            action={() => {
              dispatch(triggerOpenModal(false));
              setOpenSuccessModal(false);
              navigate("/login");
            }}
          />
        )}
      </main>
      <MusicPlayer />
    </>
  );
};

export default NewPasswordPage;
