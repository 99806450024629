import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import style from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  showMusicPlayer,
  selectMusic,
} from "redux/musicPlayer/musicPlayerSlice";
import classnames from "classnames/bind";
import Top7 from "assets/top7.jpg";
import {
  PlayCircle,
  MusicNoteBeamed,
  EyeFill,
  PersonPlusFill,
  PersonPlus,
  Share,
  PlayCircleFill,
  Pencil,
  Camera,
  XLg,
  Save,
} from "react-bootstrap-icons";
import TopContainer from "container/TopContainer";
import userDefault from "assets/user_default.jpg";
import { useParams } from "react-router-dom";
import {
  fetchUserInfo,
  fetchMusicListForSingleUser,
} from "redux/users/userSlice";
import badage5 from "assets/badge5.png";
import badage6 from "assets/badge6.png";
import badage7 from "assets/badge7.png";
import badage8 from "assets/badge8.png";
import Popup from "components/Popup";

import { RootState, useAppDispatch } from "redux/store";
import api from "api";
import { triggerLoading } from "redux/modalLoading/uiSlice";
import { isAxiosError } from "axios";
import { calculateTime } from "tools/calculateMusicTime";
import CropImageModal, { CropImageRefType } from "components/CropImageModal";
import { getLocalStorage } from "tools/queryLocalStorage";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";
const cx = classnames.bind(style);

const ComposerPage: React.FC = () => {
  const { username, avatar, isOwner, musicList, fullname, desc } = useSelector(
    (state: RootState) => state.artistDetail
  );
  const userTokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
  const { composername } = useParams();
  const [edit, setEdit] = useState<boolean>(false);
  const [editTitle, setEditTitle] = useState<string>("");
  const [editDesc, setEditDesc] = useState<string>("");
  const [openComposerMsg, setOpenComposerMsg] = useState<boolean>(false);
  const [resTitle, setResTitle] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [uploadUserImg, setUploadUserImg] = useState<Blob>();
  const actionRef = useRef<() => void>(() => {});
  const [imgUrl, setImgUrl] = useState<string>("");
  const [openCropModal, setOpenCropModal] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState("");
  const cropImgRef = useRef<CropImageRefType>(null);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const {
    uiInfo: { openModal },
  } = useSelector((state: RootState) => state);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const f = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
      });
      reader.readAsDataURL(f);
      setOpenCropModal(true);
    }
  };

  useEffect(() => {
    if (composername) {
      appDispatch(
        fetchUserInfo({
          username: composername,
          token:
            userTokenInfo && userTokenInfo.token ? userTokenInfo.token : "",
        })
      );
      appDispatch(
        fetchMusicListForSingleUser({
          specificUsername: composername,
          token:
            userTokenInfo && userTokenInfo.token ? userTokenInfo.token : "",
        })
      );
    }
  }, [composername, desc, avatar]);
  return (
    <>
      <Navbar />
      <main>
        {/* <!-- Top Area --> */}
        <TopContainer />
        {/* <!-- Main Content Area start --> */}
        <div className={cx("content")}>
          <div className={cx("user-out")}>
            {/* <!-- Head Block --> */}
            <div className={cx("user-head")}>
              {edit ? (
                <div className={cx("user-img_edit")}>
                  <label htmlFor="userImage" className={cx("user-img_editmask")}>
                    <Camera />
                  </label>
                  <input
                    type="file"
                    id="userImage"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileChange(e)
                    }
                  />
                  {imgUrl ? (
                    <div className={cx("user-head_imgInner")}>
                      <img src={imgUrl} alt="" />
                    </div>
                  ) : (
                    <div className={cx("user-head_imgInner")}>
                      <img src={avatar ? avatar : userDefault} alt="" />
                    </div>
                  )}
                </div>
              ) : (
                <div className={cx("user-head_imgInner")}>
                  <img src={avatar ? avatar : userDefault} alt="" />
                </div>
              )}
              {edit ? (
                <div className={cx("user-head_info")}>
                  <input
                    type="text"
                    className={cx("user-name_input")}
                    placeholder="Your name..."
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                  <textarea
                    className={cx("user-bio_input")}
                    placeholder="Your bio..."
                    value={editDesc}
                    onChange={(e) => setEditDesc(e.target.value)}
                  />
                  <ul>
                    <li>
                      <img src={badage5} alt="" />
                    </li>
                    <li>
                      <img src={badage6} alt="" />
                    </li>
                    <li>
                      <img src={badage7} alt="" />
                    </li>
                    <li>
                      <img src={badage8} alt="" />
                    </li>
                    <li>
                      <MusicNoteBeamed />
                      13 Tracks
                    </li>
                    <li>
                      <EyeFill />
                      32.4K View
                    </li>
                    <li>
                      <PersonPlusFill />
                      115 Followers
                    </li>
                  </ul>
                  <ol>
                    <li>
                      <button onClick={() => setEdit(false)}>
                        <XLg />
                        Cancel
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={async () => {
                          setEdit(false);
                          if (userTokenInfo && userTokenInfo.token) {
                            try {
                              dispatch(triggerLoading(true));
                              const res = await api.updateUserInfo({
                                fullname: editTitle,
                                avatar: uploadUserImg,
                                desc: editDesc,
                                username,
                                token: userTokenInfo.token,
                              });
                              dispatch(triggerLoading(false));
                              appDispatch(
                                fetchUserInfo({
                                  username: composername!,
                                  token: userTokenInfo.token,
                                })
                              );
                            } catch (error) {
                              if (isAxiosError(error)) {
                                setOpenComposerMsg(true);
                                actionRef.current = () => {
                                  setOpenComposerMsg(false);
                                };
                                setResTitle("Something went wrong...");
                                setErrorMsg(error.response?.data.message);
                              }
                              throw error;
                            }
                          }
                        }}
                      >
                        <Save />
                        Save
                      </button>
                    </li>
                  </ol>
                </div>
              ) : (
                <div className={cx("user-head_info")}>
                  <h1>{fullname}</h1>
                  <p>{desc}</p>
                  <ul>
                    <li>
                      <img src={badage5} alt="" />
                    </li>
                    <li>
                      <img src={badage6} alt="" />
                    </li>
                    <li>
                      <img src={badage7} alt="" />
                    </li>
                    <li>
                      <img src={badage8} alt="" />
                    </li>
                    <li>
                      <MusicNoteBeamed />
                      13 Tracks
                    </li>
                    <li>
                      <EyeFill />
                      32.4K View
                    </li>
                    <li>
                      <PersonPlusFill />
                      115 Followers
                    </li>
                  </ul>
                  <ol>
                    <li>
                      <button>
                        <PlayCircle />
                        Play
                      </button>
                    </li>
                    <li>
                      <button>
                        <PersonPlus />
                        Follow
                      </button>
                    </li>
                    <li>
                      <button>
                        <Share />
                        Share
                      </button>
                    </li>
                    {isOwner && (
                      <li>
                        <button
                          onClick={() => {
                            setEditTitle(fullname);
                            setEdit(true);
                            setEditDesc(desc);
                          }}
                        >
                          <Pencil />
                          Edit
                        </button>
                      </li>
                    )}
                  </ol>
                </div>
              )}
            </div>

            {/* <!-- Tracks Block --> */}
            <div className={cx("user-tracks")}>
              <h3>Tracks</h3>
              <ul>
                {musicList &&
                  musicList.map((music, index) => {
                    const {
                      title,
                      ownerUsername,
                      cover,
                      url,
                      id,
                      musicLength,
                      playCount,
                    } = music;
                    return (
                      <li
                        key={title + " " + index}
                        onClick={() => {
                          dispatch(showMusicPlayer());
                          dispatch(
                            selectMusic({
                              songTitle: title,
                              username: ownerUsername,
                              songPic: cover,
                              avatar,
                              musicSource: url,
                              musicId: id,
                            })
                          );
                        }}
                      >
                        <div className={cx("user-tracks_cover")}>
                          <PlayCircleFill />
                          <img src={cover ? cover : Top7} alt="music cover" />
                        </div>
                        <h2>{title}</h2>
                        <p>{playCount}</p>
                        <span>{calculateTime(+musicLength)}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        {/* <!-- Main Content Area end --> */}
        {openComposerMsg && openModal && (
          <Popup
            title={resTitle}
            content={errorMsg}
            buttonName="Ok"
            action={actionRef.current}
          />
        )}
        {!!imgSrc && openCropModal && (
          <CropImageModal
            closeModal={() => setOpenCropModal(false)}
            imgSrc={imgSrc}
            saveImgfile={(fileBlob: Blob, fileUrl: string) => {
              setUploadUserImg(fileBlob);
              setImgUrl(fileUrl);
            }}
            ref={cropImgRef}
          />
        )}
      </main>
      <MusicPlayer />
    </>
  );
};

export default ComposerPage;
