import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import style from "./App.module.css";
import classnames from "classnames/bind";
import LandingPage from "./container/LandingPage";
import LoginPage from "./container/LoginPage";
import UploadMusicPage from "./container/UploadMusicPage";
import SongDetailPage from "./container/SongDetailPage";
import ComposerPage from "./container/ComposerPage";
import SignUpPage from "container/SignUpPage";
import OTPConfirmPage from "container/OTPConfirmPage";
import { selectArtist } from "redux/users/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import Loading from "components/Loading";
import api from "api";
import Popup from "components/Popup";
import { triggerOpenModal } from "redux/modalLoading/uiSlice";
import ForgetPasswordPage from "container/ForgetPasswordPage";
import WavvLandingPage from "./container/WavvLandingPage";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "tools/queryLocalStorage";
import NewPasswordPage from "container/NewPasswordPage";
import SEO from "components/SEO";

const cx = classnames.bind(style);
const App: React.FC = () => {
  const [appModal, setAppModal] = useState<boolean>(false);
  const userInfo = getLocalStorage("LOGIN_USER_INFO");
  const dispatch = useDispatch();
  const { triggerLoading, openModal } = useSelector(
    (state: RootState) => state.uiInfo
  );
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [popupContent, setPopupContent] = useState<string>("");
  const actionRef = useRef<() => void>(() => {});

  const notifyLogoutInfo = () => {
    removeLocalStorage("LOGIN_USER_TOKEN_INFO");
    removeLocalStorage("LOGIN_USER_INFO");
    dispatch(triggerOpenModal(true));
    setAppModal(true);
    setPopupTitle("About Login Infomation");
    setPopupContent("Your Login has been expired.");
    actionRef.current = () => {
      dispatch(triggerOpenModal(false));
      setAppModal(false);
    };
  };

  //check wheather user login?
  useEffect(() => {
    console.log("LandingPage");
    const hour = 1000 * 60 * 60;
    if (userInfo) {
      const { username, avatar } = userInfo;
      dispatch(selectArtist({ username, avatar }));
    }
    //定期更換token
    const refreshTokenStatus = async () => {
      const loginUserTokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
      if (loginUserTokenInfo) {
        const { refreshToken } = loginUserTokenInfo;
        if (refreshToken) {
          try {
            const res = await api.refreshToken({ refreshToken });
            const {
              status,
              payload: { token, refreshToken: newRefreshToken },
            } = res;
            if (
              status === "01081" ||
              status === "01099" ||
              status === "01101"
            ) {
              notifyLogoutInfo();
            } else if (status.toString() === "0") {
              setLocalStorage("LOGIN_USER_TOKEN_INFO", {
                refreshToken: newRefreshToken,
                token,
                updateTokenTime: new Date(),
              });
            }
          } catch (error) {
            throw error;
          }
        }
      }
    };
    refreshTokenStatus();
    const refreshTimer = setInterval(refreshTokenStatus, hour * 3);
    return () => clearInterval(refreshTimer);
  }, []);

  return (
    <>
      <div className={cx("wrapper")}>
        <Routes>
          <Route index path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/uploadmusic" element={<UploadMusicPage />} />
          <Route path="/songdetail/:musicid" element={<SongDetailPage />} />
          <Route path="/composer/:composername" element={<ComposerPage />} />
          <Route path="/otpconfirm" element={<OTPConfirmPage />} />
          <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
          <Route path="/newpasswordpage" element={<NewPasswordPage />} />
          <Route path="/wavv" element={<WavvLandingPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      {triggerLoading && <Loading />}
      {openModal && appModal && (
        <Popup
          title={popupTitle}
          content={popupContent}
          buttonName="Ok"
          action={actionRef.current}
        />
      )}
      <SEO 
        title="Wavv MusicGPT" 
        description="Related keyword: AI-powered music discovery tool, Personalized music experience Seo title: Wavv MusicGPT: Improve Your Music Experience with AI Innovation 
Meta Description: Explore Wavv MusicGPT, the cutting-edge music app that incorporates AI innovation. Discover personalized playlists, curated music, and effortless exploration. Experience the future of music with Wavv MusicGPT today."
        url="https://musicgpt.wavv.app/"
      />
    </>
  );
};

export default App;
