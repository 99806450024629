export type EnvKey =
  | "REACT_APP_API_URL"
  | "REACT_APP_DEV_URL"
  | "REACT_APP_GA4"
  | "REACT_APP_STAGING_API_URL"
  | "NODE_ENV";

export interface EnvObject {
  apiUrl: string;
  stagUrl: string;
  localUrl: string;
  nodeEnv: string;
}

function getEnv(): EnvObject;
function getEnv(key: EnvKey): string;
function getEnv(key?: EnvKey) {
  if (key) {
    return process.env[key];
  }
  return {
    apiUrl: process.env["REACT_APP_API_URL"],
    stagUrl: process.env["REACT_APP_STAGING_API_URL"],
    localUrl: process.env["REACT_APP_DEV_URL"],
  };
}

export { getEnv };
export default getEnv;
