import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import ReactCrop, {
  centerCrop,
  Crop,
  PercentCrop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "hooks/useDebounceEffect";
import { canvasPreview } from "tools/canvasPreview";
import converURIToBlob from "tools/converDataUriToBlob";
const cx = classnames.bind(style);

interface CropImageModalType {
  closeModal(): void;
  saveImgfile(fileBlob: Blob, fileUrl: string): void;
  imgSrc: string;
}
export interface CropImageRefType {
  croppedImage?: Blob;
}

const CropImageModal = forwardRef<CropImageRefType, CropImageModalType>(
  ({ closeModal, saveImgfile, imgSrc }, ref) => {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
      unit: "px", // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 100,
      height: 100,
    });
    const [croppedImage, setCroppedImage] = useState<Blob>();
    const [crop, setCrop] = useState<Crop>();

    //crop image
    const centerAspectCrop = (mediaWidth: number, mediaHeight: number) => {
      return centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 90,
          },
          1,
          mediaWidth,
          mediaHeight
        ),
        mediaWidth,
        mediaHeight
      );
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;
      const aspect = {
        unit: "px" as "px",
        x: 25,
        y: 25,
        width,
        height,
      };
      setCompletedCrop(aspect);
      setCrop(centerAspectCrop(width, height));
    };

    useImperativeHandle(ref, () => ({
      croppedImage,
    }));

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            1,
            0
          );
        }
      },
      100,
      [completedCrop]
    );
    return (
      <div className={cx("crop-modal-wrap")}>
        <div className={cx("crop-modal")}>
          <div className={cx("crop-area")}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => {
                setCrop(percentCrop);
              }}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
              aspect={16 / 16}
            >
              <img ref={imgRef} src={imgSrc} alt="" onLoad={onImageLoad} />
            </ReactCrop>
          </div>
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
          <div className={cx("button-list")}>
            <button
              className={cx("button-cancel")}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>
            <button
              className={cx("button-confirm")}
              onClick={() => {
                const dataURI = previewCanvasRef.current?.toDataURL(
                  "image/jpeg",
                  0.5
                );
                if (dataURI) {
                  const croppedImage = converURIToBlob(dataURI);
                  setCroppedImage(croppedImage);
                  let imgUrl = window.URL.createObjectURL(croppedImage);
                  saveImgfile(croppedImage, imgUrl);
                }
                closeModal();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default CropImageModal;
