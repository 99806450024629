import React, { ReactElement } from "react";
import style from "./index.module.css";
import classnames from "classnames/bind";
import { X } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { triggerOpenModal } from "redux/modalLoading/uiSlice";

const cx = classnames.bind(style);

interface PopupType {
  title: string;
  content: string | ReactElement;
  buttonName: string;
  action: () => void;
}

const Popup: React.FC<PopupType> = ({ title, content, buttonName, action }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={cx("popup-mask")}
      onClick={() => {
        dispatch(triggerOpenModal(false));
      }}
    >
      <div className={cx("popup")} onClick={(e) => e.stopPropagation()}>
        <X
          onClick={() => {
            dispatch(triggerOpenModal(false));
          }}
        />
        <h1>{title}</h1>
        <p>{content}</p>
        <button onClick={action}>{buttonName}</button>
      </div>
    </div>
  );
};

export default Popup;
