import React from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  triggerOpenModal,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { getLocalStorage } from "tools/queryLocalStorage";
const cx = classnames.bind(style);

interface DropDownType {
  openUploadModal(): void;
}

const DropDown: React.FC<DropDownType> = ({ openUploadModal }) => {
  const userInfo = getLocalStorage("LOGIN_USER_INFO");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <ul className={cx("top-user_dropdown")}>
        <li>
          <div
            onClick={() => {
              dispatch(triggerOpenNavbar(false));
              dispatch(triggerOpenPersonalBoard(false));
              navigate(`/composer/${userInfo?.username}`);
            }}
          >
            Go to Profile
          </div>
        </li>
        <li>
          <div
            onClick={() => {
              dispatch(triggerOpenModal(true));
              openUploadModal();
            }}
          >
            Log Out
          </div>
        </li>
      </ul>
    </>
  );
};

export default DropDown;
