import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  username: string;
  fullname: string;
  email: string;
  tmpUserId: string;
  resetToken: string;
};

const initialState: InitialState = {
  username: "",
  fullname: "",
  email: "",
  tmpUserId: "",
  resetToken: "",
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterInfo: (state: InitialState, action) => {
      state.username = action.payload.username;
      state.fullname = action.payload.fullname;
      state.email = action.payload.email;
      state.tmpUserId = action.payload.tmpUserId;
    },
    setTmpUserId: (state: InitialState, action) => {
      state.tmpUserId = action.payload;
    },
    setResetToken: (state: InitialState, action) => {
      state.resetToken = action.payload;
    },
  },
});

export default registerSlice.reducer;
export const { setRegisterInfo, setTmpUserId, setResetToken } =
  registerSlice.actions;
