import axios, { AxiosError, AxiosInstance, isAxiosError } from "axios";
import {
  CreateNewUserParameters,
  createNewUser,
} from "./Members/createNewUser";
import {
  QueryUserLoginParameters,
  queryUserLogin,
} from "./Members/queryMemberLogin";
import {
  QueryUserLogoutParameters,
  queryUserLogout,
} from "./Members/queryMemberLogout";
import {
  QueryResetPasswordParameters,
  queryPasswordReset,
} from "./Members/queryResetPassword";
import {
  queryGetPasswordBack,
  QueryGetPasswordBackParameters,
} from "./Members/queryGetPasswordBack";
import {
  VerifyTOTPCodeParameters,
  verifyTOTPCode,
} from "./Members/verifyTOTPCode";
import { uploadMusic, UploadMusicParameter } from "./Musics/musicUpload";
import { SendTOTPCodeParameters, sendTOTPCode } from "./Members/sendTOTPCode";
import {
  QuerySingleMusicParameters,
  querySingleMusicInfo,
} from "./Musics/queryMusicInfo";
import {
  QueryUserInfoParameters,
  queryUserInfo,
} from "./Members/queryUserInfo";
import {
  deleteSingleMusic,
  DeleteSingleMusicParameters,
} from "./Musics/deleteSpecificMusic";
import { publishMusic, PublishMusicParameters } from "./Musics/publishMusic";
import {
  updateUserInfo,
  UpdateUserInfoParameters,
} from "./Members/updateUserInfo";
import {
  QueryMusicListForSpecificUserParameters,
  queryMusicListForSpecificUserInfo,
} from "./Members/queryMusicListForSingleUser";
import {
  updateMusicInfo,
  UpdateMusicInfoParameters,
} from "./Musics/updateMusicInfo";
import {
  updateMusicCount,
  UpdateMusicCountParameters,
} from "./Musics/updateMusicCount";
import getEnv from "tools/getEnv";
import { camelCaselize } from "tools/camelCaseliz";
import { refreshToken, RefreshTokenParameters } from "./Members/refreshToken";
import { queryNewUserList } from "./Members/queryNewUserList";
import { queryNewMusicList } from "./Musics/queryNewMusicList";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "tools/queryLocalStorage";
import forgetPassword, {
  QueryForgetPasswordParameters,
} from "./Members/forgetPassword";
import verifyForgetPasswordOTPCode, {
  ForgetPasswordOTPCodeParameters,
  ForgetPasswordOTPCodeResponseType,
} from "./Members/verifyForgetPasswordOTPCode";
import resetPasswordWithResetToken, {
  ResetPasswordWithResetTokenParameters,
} from "./Members/resetPasswordWithResetToken";

class Api {
  constructor() {
    this.axios = axios.create({
      baseURL: getEnv("REACT_APP_API_URL"),
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    this.axios.interceptors.request.use((config) => {
      const token = this.getToken();
      if (token && config.headers) {
        config.headers["x-wavv-token"] = token;
      }
      return config;
    });
    this.axios.interceptors.response.use(
      async (response) => {
        response.data = camelCaselize(response.data);
        return response;
      },
      async (error) => {
        if (error.response && error.response.data.status) {
          const {
            data: { status, config },
          } = error.response;
          const tokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
          if (tokenInfo) {
            let { token, refreshToken } = tokenInfo;
            if (!this.isRefreshToken) {
              this.isRefreshToken = true;
              if (status === "01102") {
                try {
                  const res = await this.refreshToken(refreshToken);
                  const {
                    payload: { refreshToken: newRefreshToken, token: newToken },
                  } = res;
                  const newTokenInfo = {
                    token: newToken,
                    refreshToken: newRefreshToken,
                    updateTokenTime: new Date(),
                  };
                  setLocalStorage("LOGIN_USER_TOKEN_INFO", newTokenInfo);
                  if (config.headers) {
                    config.headers["x-wavv-token"] = token;
                    config.baseURL = getEnv("REACT_APP_API_URL");
                  }
                  this.axios.defaults.headers["x-wavv-token"] = newToken;
                  this.requestQueue.forEach((cb) => cb(newToken));
                  this.requestQueue = [];
                  return this.axios(config);
                } catch (error) {
                } finally {
                  this.isRefreshToken = false;
                }
              } else if (
                status === "01099" ||
                status === "01101" ||
                status === "01081"
              ) {
                removeLocalStorage("LOGIN_USER_TOKEN_INFO");
                removeLocalStorage("LOGIN_USER_INFO");
              }
            } else {
              return new Promise((resolve) => {
                this.requestQueue.push((token: string) => {
                  this.axios.defaults.headers["x-wavv-token"] = token;
                  if (config.headers) {
                    config.headers["x-wavv-token"] = token;
                    config.baseURL = getEnv("REACT_APP_API_URL");
                    resolve(this.axios(config));
                  }
                });
              });
            }
          }
        }
      }
    );
  }
  private axios: AxiosInstance;
  isRefreshToken: boolean = false;
  requestQueue: any[] = [];

  getToken = () => {
    const tokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
    if (tokenInfo) {
      const { token } = tokenInfo;
      return token;
    } else {
      return "";
    }
  };

  // Register
  createNewUser = async (params: CreateNewUserParameters) => {
    return createNewUser(this.axios, params);
  };

  // login
  queryUserLogin = async (params: QueryUserLoginParameters) => {
    return queryUserLogin(this.axios, params);
  };

  //logout
  queryUserLogout = async (params: QueryUserLogoutParameters) => {
    return queryUserLogout(this.axios, params);
  };

  //reset password
  queryPasswordReset = async (params: QueryResetPasswordParameters) => {
    return queryPasswordReset(this.axios, params);
  };

  //forget password
  queryGetPasswordBack = async (params: QueryGetPasswordBackParameters) => {
    return queryGetPasswordBack(this.axios, params);
  };

  //verify TOTP code
  queryConfirmTOTPCode = async (params: VerifyTOTPCodeParameters) => {
    return verifyTOTPCode(this.axios, params);
  };

  //send TOTP code
  querySendTOTPCode = async (params: SendTOTPCodeParameters) => {
    return sendTOTPCode(this.axios, params);
  };

  postUploadMusic = async (params: UploadMusicParameter) => {
    return uploadMusic(this.axios, params);
  };

  //get single music info
  querySingleMusicInfo = async (params: QuerySingleMusicParameters) => {
    return querySingleMusicInfo(this.axios, params);
  };

  //get userInfo
  queryUserInfo = async (params: QueryUserInfoParameters) => {
    return queryUserInfo(this.axios, params);
  };

  //delete Music
  deleteSingleMusic = async (params: DeleteSingleMusicParameters) => {
    return deleteSingleMusic(this.axios, params);
  };

  //publish music
  publishMusic = async (params: PublishMusicParameters) => {
    return publishMusic(this.axios, params);
  };

  //update userInfo
  updateUserInfo = async (params: UpdateUserInfoParameters) => {
    return updateUserInfo(this.axios, params);
  };

  //query music list for user
  queryMusicListForUser = async (
    params: QueryMusicListForSpecificUserParameters
  ) => {
    return queryMusicListForSpecificUserInfo(this.axios, params);
  };

  //update music
  updateMusicInfo = async (params: UpdateMusicInfoParameters) => {
    return updateMusicInfo(this.axios, params);
  };

  //update music count
  updateMusicCount = async (params: UpdateMusicCountParameters) => {
    return updateMusicCount(this.axios, params);
  };

  //refresh token
  refreshToken = async (params: RefreshTokenParameters) => {
    return refreshToken(this.axios, params);
  };

  //query new users list
  queryNewUserList = async () => {
    return queryNewUserList(this.axios);
  };

  //query new music list
  queryNewMusicList = async () => {
    return queryNewMusicList(this.axios);
  };

  //forget password
  forgetPassword = async (params: QueryForgetPasswordParameters) => {
    return forgetPassword(this.axios, params);
  };

  //verify forget password OTP code
  verifyForgetPasswordOTPCode = async (
    params: ForgetPasswordOTPCodeParameters
  ) => {
    return verifyForgetPasswordOTPCode(this.axios, params);
  };

  //reset password with reset token
  resetPasswordWithResetToken = async (
    params: ResetPasswordWithResetTokenParameters
  ) => {
    return resetPasswordWithResetToken(this.axios, params);
  };
}

export default new Api();
