import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface VerifyTOTPCodeParameters {
  otpCode: string;
  tmpUserId: string;
}

export interface OTPConfirmResponseType extends QueryResponse {
  payload: {
    token: string;
    refreshToken: string;
  };
}

export const verifyTOTPCode = async (
  axios: AxiosInstance,
  params: VerifyTOTPCodeParameters
): Promise<OTPConfirmResponseType | QueryResponse> => {
  try {
    const { otpCode, tmpUserId } = params;
    const response = await axios.get<OTPConfirmResponseType>(
      `/users/otp/${tmpUserId}/${otpCode}`
    );
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: { token: "", refreshToken: "" },
        result: "failed",
      };
    }
    throw error;
  }
};
