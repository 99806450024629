import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface UpdateUserInfoParameters {
  username: string;
  fullname?: string;
  desc?: string;
  avatar?: Blob;
  token: string;
}

export interface UpdateUserInfoMessageType {
  message: string;
}

export const updateUserInfo = async (
  axios: AxiosInstance,
  params: UpdateUserInfoParameters
): Promise<QueryResponse> => {
  try {
    const { username, avatar, token, fullname, desc } = params;
    const formData = new FormData();
    if (avatar) {
      formData.append("avatar", avatar);
    }
    if (fullname) {
      formData.append("fullname", fullname);
    }
    if (desc) {
      formData.append("desc", desc);
    }
    const config = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axios<QueryResponse>({
      method: "POST",
      url: `/users/update/${username}`,
      data: formData,
      headers: config,
    });

    const {
      data: { message, status },
    } = response;
    return { result: "success", message, status };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
