import React, { useState } from "react";
import musicAdd from "../../assets/music-add.svg";
import classnames from "classnames/bind";
import style from "./index.module.css";
import { useNavigate } from "react-router-dom";
import userDefault from "assets/user_default.jpg";
import { useSelector, useDispatch } from "react-redux";
import DropDown from "components/DropDown";
import Popup from "components/Popup";
import {
  triggerOpenModal,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { RootState } from "redux/store";
import api from "api";
import useGetWindowSize from "hooks/useGetWindowSize";
import { getLocalStorage, removeLocalStorage } from "tools/queryLocalStorage";

const cx = classnames.bind(style);

const Upload: React.FC = () => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const userInfo = getLocalStorage("LOGIN_USER_INFO");
  const [opneUploadModal, setOpneUploadModal] = useState<boolean>(false);
  const { openPersonalBoard } = useSelector((state: RootState) => state.uiInfo);
  const { width: windowWidth } = useGetWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    uiInfo: { openModal },
    artistDetail: { username, avatar },
  } = useSelector((state: RootState) => state);
  const tokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
  const { token } = tokenInfo;
  return (
    <div
      className={cx(
        "upload-wrap",
        openPersonalBoard && windowWidth < 768 && "open-topbar"
      )}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(triggerOpenPersonalBoard(false));
      }}
    >
      <div
        className={cx(
          "top",
          openPersonalBoard && windowWidth < 768 && "open-topbar"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={cx("top-login")}
          onClick={() => {
            setOpenDropDown((prev) => !prev);
          }}
        >
          <div className={cx("top-userhead")}>
            {openDropDown && (
              <DropDown openUploadModal={() => setOpneUploadModal(true)} />
            )}
            <div className={cx("top-user-img-wrap")}>
              <img
                src={userInfo?.avatar ? userInfo.avatar : userDefault}
                alt="user-photo"
              />
            </div>
            {userInfo?.username || "Wavv Musician"}
          </div>
          <button
            className={cx("top-upload")}
            onClick={() => {
              if (windowWidth < 768) {
                dispatch(triggerOpenPersonalBoard(false));
              }
              navigate("/uploadmusic");
              dispatch(triggerOpenNavbar(false));
              dispatch(triggerOpenPersonalBoard(false));
            }}
          >
            <img src={musicAdd} alt="add music" />
            Upload
          </button>
        </div>
      </div>
      {openModal && opneUploadModal && (
        <Popup
          title="Log out"
          content="Are you sure to logout?"
          buttonName="Yes"
          action={async () => {
            if (token) {
              await api.queryUserLogout({ username, token });
            }
            dispatch(triggerOpenModal(false));
            removeLocalStorage("LOGIN_USER_TOKEN_INFO");
            removeLocalStorage("LOGIN_USER_INFO");
            navigate("/");
            dispatch(triggerOpenNavbar(false));
            dispatch(triggerOpenPersonalBoard(false));
            window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default Upload;
