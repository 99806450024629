import { AxiosInstance } from "axios";
import { QueryResponse } from "../type";
export interface CreateNewUserParameters {
  username: string;
  email: string;
  fullname: string;
  password: string;
}

interface CreateNewUserResponseType extends QueryResponse {
  payload: {
    tmpUserId: string;
  };
}

export const createNewUser = async (
  axios: AxiosInstance,
  params: CreateNewUserParameters
): Promise<CreateNewUserResponseType> => {
  const { username, email, fullname, password } = params;
  let requestBody = {
    username,
    email,
    fullname,
    password,
    role: "listener",
  };
  try {
    const response = await axios.post<CreateNewUserResponseType>(
      `/users`,
      JSON.stringify(requestBody)
    );
    const {
      data: { message, status, payload },
    } = response;

    if (status === "0") {
      return { message, payload, status, result: "success" };
    } else {
      return { message, payload, status, result: "failed" };
    }
  } catch (error) {
    throw error;
  }
};
