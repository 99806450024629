import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api";
import { triggerLoading } from "redux/modalLoading/uiSlice";
import { QueryMusicListForSpecificUserParameters } from "api/Members/queryMusicListForSingleUser";
import { QueryUserInfoParameters } from "api/Members/queryUserInfo";
import { getLocalStorage, setLocalStorage } from "tools/queryLocalStorage";

export type MusicDetailType = {
  cover: string;
  createdAt: string;
  desc: string;
  extension: string;
  filename: string;
  id: string;
  isOwner: boolean;
  isPublished: boolean;
  ownerFullname: string;
  ownerUsername: string;
  musicLength: string;
  playCount: string;
  title: string;
  url: string;
};

export type UserInfoType = {
  username: string;
  avatar: string;
  desc: string;
  isOwner: boolean;
  email: string;
  fullname: string;
  musicCount: number;
  musicList: MusicDetailType[];
  errorMsg: string;
};

const initialState: UserInfoType = {
  username: "",
  avatar: "",
  isOwner: false,
  desc: "",
  email: "",
  fullname: "",
  musicCount: 0,
  errorMsg: "",
  musicList: [],
};

export const fetchUserInfo = createAsyncThunk(
  "artist/fetchUserInfo",
  async (params: QueryUserInfoParameters, thunkApi) => {
    try {
      thunkApi.dispatch(triggerLoading(true));
      const res = await api.queryUserInfo(params);
      const {
        payload: {
          username,
          avatar,
          email,
          fullname,
          isOwner,
          musicCount,
          desc,
        },
      } = res;
      const loginUserInfo = getLocalStorage("LOGIN_USER_INFO");
      //如果userInfo有更新，則更新localstorage內的資料
      if (loginUserInfo.username === username) {
        setLocalStorage("LOGIN_USER_INFO", {
          username,
          avatar,
          desc,
          fullname,
          email,
        });
      }
      const { getUserInfo } = artistSlice.actions;
      thunkApi.dispatch(
        getUserInfo({
          username: username,
          avatar: avatar,
          isOwner: isOwner,
          email: email,
          fullname: fullname,
          desc: desc,
          musicCount: musicCount,
        })
      );
      thunkApi.dispatch(triggerLoading(false));
    } catch (error) {
      thunkApi.dispatch(triggerLoading(false));
      throw error;
    }
  }
);

export const fetchMusicListForSingleUser = createAsyncThunk(
  "artist/fetchMusicListForSingleUser",
  async (params: QueryMusicListForSpecificUserParameters, thunkApi) => {
    const { fetchMusicListForComposer, setErrorMsg } = artistSlice.actions;
    try {
      const res = await api.queryMusicListForUser(params);
      const { payload } = res;
      thunkApi.dispatch(fetchMusicListForComposer(payload));
    } catch (error) {
      thunkApi.dispatch(setErrorMsg(error));
      throw error;
    }
  }
);

const artistSlice = createSlice({
  name: "artist",
  initialState,
  reducers: {
    selectArtist: (state: UserInfoType, action) => {
      state.username = action.payload.username;
      state.avatar = action.payload.avatar;
    },
    checkOwnThePage: (state: UserInfoType, action) => {
      state.isOwner = action.payload;
    },
    getUserInfo: (state: UserInfoType, action) => {
      state.avatar = action.payload.avatar;
      state.username = action.payload.username;
      state.desc = action.payload.desc;
      state.email = action.payload.email;
      state.fullname = action.payload.fullname;
      state.isOwner = action.payload.isOwner;
      state.musicCount = action.payload.musicCount;
    },
    fetchMusicListForComposer: (state: UserInfoType, action) => {
      state.musicList = action.payload;
    },
    setErrorMsg: (state: UserInfoType, action) => {
      state.errorMsg = action.payload;
    },
  },
});

export default artistSlice.reducer;
export const { selectArtist } = artistSlice.actions;
