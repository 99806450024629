import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
  useDispatch,
} from "react-redux";
import musicPlayerReducer from "./musicPlayer/musicPlayerSlice";
import userReducer from "./users/userSlice";
import songDetailReducer from "./songDetail/songDetailSlice";
import registerInfoReducer from "./registerUserInfo/registerSlice";
import uiDetailReducer from "./modalLoading/uiSlice";
import landingPageInfoReducer from "./landingPageInfo/landingPageInfoSlice";

const rootReducer = combineReducers({
  musicPlayer: musicPlayerReducer,
  artistDetail: userReducer,
  songDetail: songDetailReducer,
  registerInfo: registerInfoReducer,
  uiInfo: uiDetailReducer,
  landingPageInfo: landingPageInfoReducer,
});

const store = configureStore({
  reducer: rootReducer,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
