import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface QueryUserLoginParameters {
  email: string;
  password: string;
}

export interface LoginResponseType extends QueryResponse {
  payload: {
    avatar: string;
    desc: string;
    email: string;
    fullname: string;
    isOwner: boolean;
    musicCount: number;
    refreshToken: string;
    token: string;
    username: string;
  };
}

export const queryUserLogin = async (
  axios: AxiosInstance,
  params: QueryUserLoginParameters
): Promise<LoginResponseType | QueryResponse> => {
  try {
    const { email, password } = params;
    let requestBody = {
      email,
      password,
    };
    const response = await axios.post<LoginResponseType>(
      `/users/login`,
      JSON.stringify(requestBody)
    );
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: {
          avatar: "",
          desc: "",
          email: "",
          fullname: "",
          isOwner: false,
          musicCount: 0,
          refreshToken: "",
          token: "",
          username: "",
        },
        result: "failed",
      };
    }
    throw error;
  }
};
