import React, { useState } from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import wallet2 from "assets/wallet2.png";
import api from "api";
import { useNavigate } from "react-router-dom";
import {
  validateEmail,
  validatePassword,
  validateUserName,
} from "tools/validate";
import Popup from "components/Popup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TopContainer from "container/TopContainer";
import { setRegisterInfo } from "redux/registerUserInfo/registerSlice";
import { RootState } from "redux/store";
import {
  triggerLoading,
  triggerOpenModal,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { isAxiosError } from "axios";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

const SignUpPage: React.FC = () => {
  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [fullname, setFullName] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const dispatch = useDispatch();
  const {
    uiInfo: { openModal },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content", "signin-wrapper")}>
          {/* <!-- Main Content Area start --> */}
          <div className={cx("signin-out")}>
            <div className={cx("signout-email")}>
              <h2>Sign Up With Email</h2>
              <p>User Name</p>
              <input
                type="text"
                placeholder="up to 25 characters"
                onChange={(e) => setUserName(e.target.value)}
              />
              <p>Email Address</p>
              <input
                type="email"
                placeholder="example@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
              <p>Full Name</p>
              <input
                type="text"
                placeholder="up to 25 characters"
                onChange={(e) => setFullName(e.target.value)}
              />
              <p>Password</p>
              <input
                type="password"
                placeholder="at least 8 characters"
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="submit"
                value="Sign Up"
                onClick={async () => {
                  setErrorMsg("");
                  if (username.length === 0) {
                    setErrorMsg("Username should not be empty.");
                    return;
                  } else if (fullname.length === 0) {
                    setErrorMsg("Full name should not be empty.");
                    return;
                  } else if (email.length === 0) {
                    setErrorMsg("Email should not be empty");
                    return;
                  } else if (password.length === 0) {
                    setErrorMsg("Password should not be empty");
                    return;
                  }

                  if (!validateEmail(email)) {
                    setErrorMsg("Email formate is not correct!");
                    return;
                  }

                  if (!validatePassword(password)) {
                    dispatch(triggerOpenModal(true));
                    return;
                  }

                  if (!validateUserName(username)) {
                    setErrorMsg(
                      "Username should more than 3 charactors and less than 32.."
                    );
                    return;
                  }
                  try {
                    dispatch(triggerLoading(true));
                    const res = await api.createNewUser({
                      username,
                      password,
                      email,
                      fullname,
                    });
                    dispatch(triggerLoading(false));

                    const {
                      message,
                      result,
                      payload: { tmpUserId },
                    } = res;

                    if (result === "success") {
                      setErrorMsg("");
                      dispatch(
                        setRegisterInfo({
                          username,
                          fullname,
                          email,
                          tmpUserId,
                        })
                      );
                      dispatch(triggerOpenNavbar(false));
                      dispatch(triggerOpenPersonalBoard(false));
                      navigate("/otpconfirm", {
                        state: { type: "register" },
                      });
                    } else {
                      setErrorMsg(message);
                    }
                  } catch (error) {
                    dispatch(triggerLoading(false));
                    if (isAxiosError(error)) {
                      setErrorMsg(error.response?.data.message);
                    }
                  }
                }}
              />
              <p className={cx("error-msg")}>{errorMsg}</p>
              <ul className={cx("helper-option")}>
                <li>
                  <a href="#">Forget Password</a>
                </li>
                <li
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Already a member?
                </li>
              </ul>
            </div>
            <p className={cx("signin-or")}>or</p>
            <div className={cx("signin-wallet")}>
              <h2>Sign In With Wallet</h2>
              <img src={wallet2} alt="" />
              <p>coming soon!</p>
            </div>
          </div>
        </div>
        {openModal && (
          <Popup
            title="Password must have:"
            content={<PasswordTip />}
            buttonName="ok"
            action={() => {
              dispatch(triggerOpenModal(false));
            }}
          />
        )}
      </main>
      <MusicPlayer />
    </>
  );
};

export const PasswordTip: React.FC = () => {
  return (
    <div className={cx("password-tip")}>
      <p>1. a minimum of 1 lower case letter [a-z]</p>
      <p>2. a minimum of 1 upper case letter [A-Z]</p>
      <p>3. a minimum of 1 numeric character [0-9]</p>
      <p>4. a minimum of 1 special character.</p>
      <p>5. a minimum of 7 characters in length and 33 maximum.</p>
    </div>
  );
};

export default SignUpPage;
