import { AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface QueryGetPasswordBackParameters {
  email: string;
}

export interface GetPasswordBackMessageType {
  message: string;
}

export const queryGetPasswordBack = async (
  axios: AxiosInstance,
  params: QueryGetPasswordBackParameters
): Promise<QueryResponse> => {
  const { email } = params;
  let requestBody = {
    email,
  };
  const config = {
    headers: {
      maxContentLength: 31,
    },
  };
  const response = await axios.post<QueryResponse>(
    `/users/forget`,
    JSON.stringify(requestBody),
    config
  );

  const { data: responseData } = response;
  return responseData;
};
