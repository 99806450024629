import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export type MusicListType = {
  cover: string;
  createdAt: Date;
  filename: string;
  musicLength: number;
  url: string;
  avatar: string;
  ownerUsername: string;
  ownerFullname: string;
  id: string;
};

export interface QueryNewUserMusicResponseType extends QueryResponse {
  payload: MusicListType[];
}

export const queryNewMusicList = async (
  axios: AxiosInstance
): Promise<QueryNewUserMusicResponseType> => {
  try {
    const response = await axios.get<QueryNewUserMusicResponseType>(
      `/music?limit=10&sort=created_at&order=desc`
    );
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: [],
        result: "failed",
      };
    }
    throw error;
  }
};
