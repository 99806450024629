import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface PublishMusicParameters {
  username: string;
  musicId: string;
  token: string;
}

export interface PublishMusicMusicMessageType {
  message: string;
}

export const publishMusic = async (
  axios: AxiosInstance,
  params: PublishMusicParameters
): Promise<QueryResponse> => {
  try {
    const { username, musicId, token } = params;
    const requestBody = {
      username,
      music_id: musicId,
    };
    const response = await axios.post<QueryResponse>(
      `/music/publish`,
      JSON.stringify(requestBody)
    );

    const { data: responseData } = response;
    return responseData;
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
