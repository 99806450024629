import React, { useState } from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import wallet2 from "assets/wallet2.png";
import { useNavigate } from "react-router-dom";
import TopContainer from "container/TopContainer";
import { validateEmail } from "tools/validate";
import { LoginResponseType } from "api/Members/queryMemberLogin";
import { useDispatch } from "react-redux";
import { selectArtist } from "redux/users/userSlice";
import {
  triggerLoading,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import api from "api";
import { AxiosError } from "axios";
import { setLocalStorage } from "tools/queryLocalStorage";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const dispatch = useDispatch();
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content", "signin-wrapper")}>
          {/* <!-- Main Content Area start --> */}
          <div className={cx("signin-out")}>
            <div className={cx("signin-email")}>
              <h2>Sign In With Email</h2>
              <p>Email Address</p>
              <input
                type="email"
                placeholder="example@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
              <p>Password</p>
              <input
                type="password"
                placeholder="Your password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="submit"
                value="Sign In"
                onClick={async () => {
                  setErrorMsg("");
                  if (email.length === 0) {
                    setErrorMsg("Email should not be empty");
                    return;
                  } else if (password.length === 0) {
                    setErrorMsg("Password should not be empty");
                    return;
                  }

                  if (!validateEmail(email)) {
                    setErrorMsg("Email formate is not correct!");
                    return;
                  }
                  try {
                    dispatch(triggerLoading(true));
                    const res = await api.queryUserLogin({
                      email,
                      password,
                    });
                    dispatch(triggerLoading(false));
                    if (res.result === "success") {
                      const {
                        payload: {
                          token,
                          refreshToken,
                          username,
                          avatar,
                          fullname,
                          desc,
                          email,
                        },
                      } = res as LoginResponseType;
                      setLocalStorage("LOGIN_USER_TOKEN_INFO", {
                        token,
                        refreshToken,
                        updateTokenTime: new Date(),
                      });
                      setLocalStorage("LOGIN_USER_INFO", {
                        username,
                        avatar,
                        desc,
                        fullname,
                        email,
                      });
                      dispatch(selectArtist({ username, avatar }));
                      navigate(`/composer/${username}`);
                      dispatch(triggerOpenNavbar(false));
                      dispatch(triggerOpenPersonalBoard(false));
                    } else {
                      setErrorMsg(res.message as string);
                    }
                  } catch (error) {
                    dispatch(triggerLoading(false));
                    if (error instanceof AxiosError) {
                      setErrorMsg(error.response?.data.message);
                    }
                    throw error;
                  }
                }}
              />
              <p className={cx("error-msg")}>{errorMsg}</p>
              <ul className={cx("helper-option")}>
                <li
                  className={cx("sign")}
                  onClick={() => {
                    navigate("/forgetpassword");
                  }}
                >
                  Forget Password
                </li>
                <li
                  className={cx("sign")}
                  onClick={() => {
                    dispatch(triggerOpenNavbar(false));
                    dispatch(triggerOpenPersonalBoard(false));
                    navigate("/signup");
                  }}
                >
                  Need an account?
                </li>
              </ul>
            </div>
            <p className={cx("signin-or")}>or</p>
            <div className={cx("signin-wallet")}>
              <h2>Sign In With Wallet</h2>
              <img src={wallet2} alt="" />
              <p>coming soon!</p>
            </div>
          </div>
        </div>
      </main>
      <MusicPlayer />
    </>
  );
};

export default LoginPage;
