import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface UpdateMusicInfoParameters {
  musicId: string;
  file?: Blob;
  title?: string;
  desc?: string;
  cover?: Blob;
  token: string;
}

export interface UpdateMusicInfoMessageType {
  message: string;
}

export const updateMusicInfo = async (
  axios: AxiosInstance,
  params: UpdateMusicInfoParameters
): Promise<QueryResponse> => {
  try {
    const { musicId, title, token, cover, desc, file } = params;
    const formData = new FormData();
    formData.append("music_id", musicId);
    formData.append("ownership", "100");
    if (file) {
      formData.append("file", file);
    }
    if (cover) {
      formData.append("cover", cover);
    }
    if (desc) {
      formData.append("desc", desc);
    }
    if (title) {
      formData.append("title", title);
    }
    const config = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axios<QueryResponse>({
      method: "POST",
      url: `/music/update`,
      data: formData,
      headers: config,
    });

    const {
      data: { message, status },
    } = response;
    return { result: "success", message, status };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
