import { useEffect, useState } from "react";

const getWindowSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export interface ScreenSizeType {
  width: number;
  height: number;
}

const useGetWindowSize = () => {
  const [windowSize, setWindowSize] = useState<ScreenSizeType>(getWindowSize());
  useEffect(() => {
    const handleResize = () => setWindowSize(getWindowSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export default useGetWindowSize;
