import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { MemberListType } from "api/Members/queryNewUserList";
import { MusicListType } from "api/Musics/queryNewMusicList";

type InitialState = {
  memberList: MemberListType[];
  musicList: MusicListType[];
};

const initialState: InitialState = {
  memberList: [],
  musicList: [],
};

export const fetchMemberList = createAsyncThunk(
  "landingPageInfo/fetchMemberList",
  async (_, thunkApi) => {
    // const { queryMemberList } = landingPageInfoSlice.actions;
    try {
      const res = await api.queryNewUserList();
      const { payload } = res;
      thunkApi.dispatch(queryMemberList(payload));
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMusicList = createAsyncThunk(
  "landingPageInfo/fetchMusicList",
  async (_, thunkApi) => {
    try {
      const res = await api.queryNewMusicList();
      const { payload } = res;
      thunkApi.dispatch(queryMusicList(payload));
    } catch (error) {
      throw error;
    }
  }
);

const landingPageInfoSlice = createSlice({
  name: "landingPageInfo",
  initialState,
  reducers: {
    queryMemberList: (
      state: InitialState,
      action: PayloadAction<MemberListType[]>
    ) => {
      state.memberList = action.payload;
    },
    queryMusicList: (
      state: InitialState,
      action: PayloadAction<MusicListType[]>
    ) => {
      state.musicList = action.payload;
    },
  },
});

export default landingPageInfoSlice.reducer;
export const { queryMemberList, queryMusicList } = landingPageInfoSlice.actions;
