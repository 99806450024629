import React, { InputHTMLAttributes, useEffect } from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import { RootState } from "redux/store";
import {
  VolumeUpFill,
  ArrowRepeat,
  SkipStartFill,
  SkipEndFill,
  Shuffle,
  PlayCircle,
  PauseCircle,
  StopCircle,
  ArrowLeftRight,
} from "react-bootstrap-icons";
import Top7 from "assets/top7.jpg";
import song1 from "assets/music/DemoAudio.mp3";
import song2 from "assets/music/Climbing.mp3";
import song3 from "assets/music/DeathMatch.mp3";
import song4 from "assets/music/Pooka.mp3";
import song5 from "assets/music/RideOfTheValkyries.mp3";
import song6 from "assets/music/SurfingLlama.mp3";

import { useDispatch, useSelector } from "react-redux";
import {
  addMusicPlayCount,
  closeMusicPlayer,
} from "redux/musicPlayer/musicPlayerSlice";
import { useAppDispatch } from "redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchMusicByMusicId } from "redux/songDetail/songDetailSlice";
import { calculateTime } from "tools/calculateMusicTime";
import useGetWindowSize from "hooks/useGetWindowSize";
import {
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { getLocalStorage } from "tools/queryLocalStorage";

const cx = classnames.bind(style);

const MusicPlayer: React.FC = () => {
  const {
    showPlayer,
    songDetail: { songTitle, username, songPic, avatar, musicId },
  } = useSelector((state: RootState) => state.musicPlayer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const location = useLocation();
  const paramsId = location.pathname.split("/").at(-1);
  const tokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
  return (
    <div className={cx("player", showPlayer ? "show" : "close")}>
      <div
        className={cx("player-info")}
        onClick={() => {
          if (paramsId === musicId) {
            return;
          }
          dispatch(triggerOpenNavbar(false));
          dispatch(triggerOpenPersonalBoard(false));
          navigate(`/songdetail/${musicId}`);
          appDispatch(
            fetchMusicByMusicId({
              musicId,
              token: tokenInfo.token ? tokenInfo.token : "",
            })
          );
          dispatch(closeMusicPlayer());
        }}
      >
        <img src={songPic ? songPic : Top7} alt="" />
        <h2>{songTitle}</h2>
        <span
          onClick={() => {
            dispatch(triggerOpenNavbar(false));
            dispatch(triggerOpenPersonalBoard(false));
            navigate(`/composer/${username}`);
          }}
        >
          {username}
        </span>
      </div>
      <AudioPlayer />
    </div>
  );
};

const AudioPlayer: React.FC = () => {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [duration, setDuration] = React.useState<number>(0);
  const songArr: string[] = [song1, song2, song3, song4, song5, song6];
  const [currentSongIndex, setCurrentSongIndex] = React.useState<number>(0);
  const [isLoop, setIsLoop] = React.useState<boolean>(false);
  const [shuffle, setShuffle] = React.useState<boolean>(false);
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const appDispatch = useAppDispatch();
  const { width: windowWidth } = useGetWindowSize();
  const mobileSize = windowWidth < 768;
  const deskTopSize = windowWidth > 768;

  //reference
  const audioPlayerRef = React.useRef<HTMLAudioElement>(null);
  const progressbarRef = React.useRef<HTMLInputElement>(null);
  const volumnbarRef = React.useRef<HTMLInputElement>(null);
  const animationRef = React.useRef<number>(0);
  const {
    songDetail: { musicSource, musicId },
  } = useSelector((state: RootState) => state.musicPlayer);

  //開啟或暫停音樂
  const handlePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayerRef.current?.play();
      animationRef.current = requestAnimationFrame(movePlayingAnimation);
      appDispatch(addMusicPlayCount(musicId));
    } else {
      audioPlayerRef.current?.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  //進度條動畫
  const movePlayingAnimation = () => {
    if (!progressbarRef.current || !audioPlayerRef.current) {
      return;
    }
    progressbarRef.current.value =
      audioPlayerRef.current.currentTime.toString();
    changeProgressCurrentTime();
    animationRef.current = requestAnimationFrame(movePlayingAnimation);
  };

  //下一首
  const handleNextSong = () => {
    audioPlayerRef.current?.pause();
    if (currentSongIndex === songArr.length - 1) {
      if (!isLoop) {
        setIsPlaying(false);
      }
      setCurrentSongIndex(0);
      return;
    }
    setCurrentSongIndex((prevIdx) => {
      if (prevIdx === songArr.length - 1) {
        return prevIdx;
      }
      return prevIdx + 1;
    });
  };

  //上一首
  const handleLastSong = () => {
    audioPlayerRef.current?.pause();
    if (currentSongIndex === 0) {
      setIsPlaying(false);
      setCurrentSongIndex(0);
      return;
    }
    setCurrentSongIndex((prevIdx) => {
      if (prevIdx === 0) {
        return prevIdx;
      }
      return prevIdx - 1;
    });
  };

  //開啟關閉循環播放
  const triggerRepeat = () => {
    setIsLoop((prev) => !prev);
  };

  //開啟或關閉隨機播放
  const onShuffle = () => {
    setShuffle((prev) => !prev);
  };

  //控制progressbar
  const handleRangeChange = () => {
    if (!audioPlayerRef.current || !progressbarRef.current) {
      return;
    }
    audioPlayerRef.current.currentTime = +progressbarRef.current.value;
    changeProgressCurrentTime();
  };

  const changeProgressCurrentTime = () => {
    if (!progressbarRef.current || !audioPlayerRef.current) {
      return;
    }
    progressbarRef.current.style.setProperty(
      "--seek-before-width",
      `${(+progressbarRef.current.value / duration) * 100}%`
    );
    setCurrentTime(+progressbarRef.current.value);
  };

  //調整音量
  const handleVolumnChange = (event: { target: HTMLInputElement }) => {
    if (!audioPlayerRef.current || !volumnbarRef.current) {
      return;
    }
    audioPlayerRef.current.volume = +event.target.value / 100;
  };

  useEffect(() => {
    if (audioPlayerRef.current) {
      const seconds = Math.floor(audioPlayerRef.current?.duration);
      setDuration(seconds);
      if (progressbarRef.current) {
        progressbarRef.current.max = seconds.toString();
      }
    }
  }, [
    audioPlayerRef.current?.onloadedmetadata,
    audioPlayerRef.current?.readyState,
    musicSource,
  ]);

  useEffect(() => {
    if (isPlaying) {
      audioPlayerRef.current?.play();
    }
  }, [currentSongIndex]);

  //曲目結束自動調整進度為0
  useEffect(() => {
    if (audioPlayerRef.current && currentTime === duration) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [progressbarRef.current?.value, musicSource]);

  useEffect(() => {
    setIsPlaying(false);
  }, [musicSource]);
  return (
    <>
      <div className={cx("music-player-wrap")}>
        <div className={cx("player-control")}>
          <audio
            ref={audioPlayerRef}
            src={musicSource ? musicSource : songArr[currentSongIndex]}
            preload="metadata"
          />
          {/* repeat btn */}
          {deskTopSize &&
            (isLoop ? (
              <ArrowLeftRight onClick={triggerRepeat} />
            ) : (
              <ArrowRepeat onClick={triggerRepeat} />
            ))}
          {/* last song btn */}
          {deskTopSize && <SkipStartFill onClick={handleLastSong} />}
          {isPlaying ? (
            <PauseCircle onClick={handlePlayPause} />
          ) : (
            <PlayCircle onClick={handlePlayPause} />
          )}
          {deskTopSize && <SkipEndFill onClick={handleNextSong} />}
          {/* <StopCircle /> */}
          {deskTopSize && <Shuffle onClick={onShuffle} />}
        </div>
        {deskTopSize && (
          <div className={cx("progressbar")}>
            <div className={cx("current-time-duration")}>
              {calculateTime(currentTime)}
            </div>
            <input
              type="range"
              defaultValue={0}
              ref={progressbarRef}
              onChange={handleRangeChange}
            />
            <div className={cx("current-time-duration")}>
              {calculateTime(duration)}
            </div>
          </div>
        )}
      </div>
      {deskTopSize && (
        <div className={cx("player-volume")}>
          <VolumeUpFill />
          <input
            type="range"
            max={100}
            min={0}
            defaultValue={50}
            ref={volumnbarRef}
            onChange={handleVolumnChange}
          />
        </div>
      )}
    </>
  );
};

export default MusicPlayer;
