import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import classnames from "classnames/bind";
import { useSelector } from "react-redux";
import style from "./index.module.css";
import { RootState } from "redux/store";
import api from "api";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectArtist } from "redux/users/userSlice";
import TopContainer from "container/TopContainer";
import { OTPConfirmResponseType } from "api/Members/verifyTOTPCode";
import userDefault from "assets/user_default.jpg";
import {
  triggerLoading,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { setLocalStorage } from "tools/queryLocalStorage";
import { setResetToken } from "redux/registerUserInfo/registerSlice";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

const OTPConfirmPage: React.FC = () => {
  const [otp, setOTP] = useState<string[]>(new Array(6).fill(""));
  const firstInputRef = useRef<HTMLInputElement | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { username, tmpUserId, fullname, email } = useSelector(
    (state: RootState) => state.registerInfo
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    state: { type },
  } = location;

  const handleInsertOTP = (
    ref: EventTarget & HTMLInputElement,
    index: number
  ) => {
    if (isNaN(+ref.value) || !ref.value) {
      return;
    }
    setOTP([
      ...otp.map((val, idx) => {
        return idx === index ? ref.value : val;
      }),
    ]);
    if (ref.nextElementSibling) {
      (ref.nextElementSibling as HTMLInputElement).focus();
    }
  };

  const registerOTPConfirm = async () => {
    const OTP = otp.join("");
    try {
      dispatch(triggerLoading(true));
      const res = await api.queryConfirmTOTPCode({
        otpCode: OTP,
        tmpUserId,
      });
      dispatch(triggerLoading(false));
      if (res.result === "success") {
        const {
          payload: { token, refreshToken },
        } = res as OTPConfirmResponseType;

        navigate(`/composer/${username}`);
        dispatch(triggerOpenNavbar(false));
        dispatch(triggerOpenPersonalBoard(false));
        setLocalStorage("LOGIN_USER_TOKEN_INFO", {
          token,
          refreshToken,
          updateTokenTime: new Date(),
        });
        setLocalStorage("LOGIN_USER_INFO", {
          username,
          avatar: userDefault,
          desc: "",
          email,
          fullname,
        });
        dispatch(
          selectArtist({
            username: username,
            avatar: userDefault,
          })
        );
      } else {
        setErrorMsg(res.message);
      }
    } catch (error) {
      dispatch(triggerLoading(false));
    }
  };

  const forgetPasswordConfirm = async () => {
    const OTP = otp.join("");
    try {
      dispatch(triggerLoading(true));
      const res = await api.verifyForgetPasswordOTPCode({
        otpCode: OTP,
        tmpUserId,
      });
      dispatch(triggerLoading(false));
      const {
        payload: { resetToken },
      } = res;
      dispatch(setResetToken(resetToken));
      navigate("/newpasswordpage");
    } catch (error) {
      dispatch(triggerLoading(false));
      throw error;
    }
  };

  useEffect(() => {
    firstInputRef.current?.focus();
  }, []);
  useLayoutEffect(() => {
    if (otp.length === 0) {
      setOTP(new Array(6).fill(""));
    } else if (!otp.join("")) {
      firstInputRef.current?.focus();
    }
  }, [otp]);
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content", "au-su")}>
          <div className={cx("vf-code")}>
            <h1>Security Verification</h1>
            <p>
              To secure your account, please complete the following verification.
            </p>
            <div className={cx("otp-box")}>
              <p>Verification code</p>
              {otp.map((_, index) => {
                return index === 0 ? (
                  <input
                    type="text"
                    maxLength={1}
                    key={index}
                    value={otp[index]}
                    ref={firstInputRef}
                    onChange={(e) => {
                      handleInsertOTP(e.target, index);
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                ) : (
                  <input
                    type="text"
                    maxLength={1}
                    key={index}
                    onChange={(e) => {
                      handleInsertOTP(e.target, index);
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            <div className={cx("button-panel")}>
              <button
                onClick={() => {
                  setOTP([]);
                }}
              >
                Clear
              </button>
              <button
                onClick={async () => {
                  type === "register"
                    ? registerOTPConfirm()
                    : forgetPasswordConfirm();
                }}
              >
                Confirm
              </button>
            </div>
            <p
              onClick={() => {
                api.querySendTOTPCode({ username });
              }}
            >
              Resend Verification Code
            </p>
            <div className={cx("error-msg")}>{errorMsg}</div>
          </div>
        </div>
      </main>
      <MusicPlayer />
    </>
  );
};

export default OTPConfirmPage;
