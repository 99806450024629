import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface QueryResetPasswordParameters {
  email: string;
}

export interface GetResetPasswordMessageType {
  message: string;
}

export const queryPasswordReset = async (
  axios: AxiosInstance,
  params: QueryResetPasswordParameters
): Promise<QueryResponse> => {
  try {
    const { email } = params;
    let requestBody = {
      email,
    };
    const config = {
      headers: {
        maxContentLength: 78,
      },
    };
    const response = await axios.post<QueryResponse>(
      `/users/reset`,
      JSON.stringify(requestBody),
      config
    );

    const { data: responseData } = response;
    return responseData;
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
