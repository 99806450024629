import React, { useEffect } from "react";
import style from "./index.module.css";
import classnames from "classnames/bind";
import { useDispatch } from "react-redux";
import { closeMusicPlayer } from "redux/musicPlayer/musicPlayerSlice";
import logo from "assets/logo.svg";
import {
  GraphUpArrow,
  Search,
  Heart,
  ClockHistory,
  House,
  List,
  PersonCircle,
  X,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import useGetWindowSize from "hooks/useGetWindowSize";
import {
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
const cx = classnames.bind(style);

const Navbar: React.FC = () => {
  const { openNavbar, openPersonalBoard } = useSelector(
    (state: RootState) => state.uiInfo
  );
  const { width: windowWidth } = useGetWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <nav>
      {openNavbar ? (
        <X onClick={() => dispatch(triggerOpenNavbar(false))} />
      ) : (
        <List
          onClick={() => {
            dispatch(triggerOpenNavbar(true));
            dispatch(triggerOpenPersonalBoard(false));
          }}
        />
      )}
      <div
        className={cx("logo")}
        onClick={() => {
          dispatch(triggerOpenNavbar(false));
          dispatch(triggerOpenPersonalBoard(false));
          navigate("/");
          dispatch(closeMusicPlayer());
        }}
      >
        <img src={logo} alt="logo" />
      </div>
      <PersonCircle
        onClick={() => {
          dispatch(triggerOpenPersonalBoard(!openPersonalBoard));
          dispatch(triggerOpenNavbar(false));
        }}
      />
      <div
        className={cx(
          "nav-wrap",
          openNavbar && windowWidth < 768 && "open-navbar"
        )}
        onClick={() => {
          dispatch(triggerOpenNavbar(false));
        }}
      >
        <ul
          className={cx(
            "nav-main",
            openNavbar && windowWidth < 768 && "open-navbar"
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <li
            onClick={(e) => {
              e.stopPropagation();
              dispatch(triggerOpenNavbar(false));
              dispatch(triggerOpenPersonalBoard(false));
              navigate("./");
              dispatch(triggerOpenNavbar(false));
            }}
          >
            <House />
            Home
          </li>
          <li style={{ color: "#ccc", pointerEvents: "none" }}>
            <GraphUpArrow />
            Trending
          </li>
          <li style={{ color: "#ccc", pointerEvents: "none" }}>
            <Search />
            Search
          </li>
          <li style={{ color: "#ccc", pointerEvents: "none" }}>
            <Heart />
            Favorite
          </li>
          <li style={{ color: "#ccc", pointerEvents: "none" }}>
            <ClockHistory />
            History
          </li>
        </ul>
        <ul className={cx("nav-sub")}>
          <li style={{ pointerEvents: "none" }}>
            <a href="#">About Us</a>
          </li>
          <li style={{ pointerEvents: "none" }}>
            <a href="#">Terms of Use</a>
          </li>
          <li style={{ pointerEvents: "none" }}>
            <a href="#">Privacy Policy</a>
          </li>
          <li style={{ pointerEvents: "none" }}>
            <a href="#">Follow Us</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
