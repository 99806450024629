import { AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface QueryForgetPasswordParameters {
  email: string;
}

export interface ForgetPasswordResponseType extends QueryResponse {
  payload: {
    tmpUserId: string;
  };
}

export const queryForgetPassword = async (
  axios: AxiosInstance,
  params: QueryForgetPasswordParameters
): Promise<ForgetPasswordResponseType> => {
  try {
    const { email } = params;
    let requestBody = {
      email,
    };
    const config = {
      maxContentLength: 31,
    };

    const response = await axios.post<ForgetPasswordResponseType>(
      `/users/forget`,
      JSON.stringify(requestBody),
      config
    );

    const { data: responseData } = response;
    return responseData;
  } catch (error) {
    throw error;
  }
};

export default queryForgetPassword;
