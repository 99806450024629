import React from "react";
import classnames from "classnames/bind";
import style from "./index.module.css";
import { Person } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import useGetWindowSize from "hooks/useGetWindowSize";
import {
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";

const cx = classnames.bind(style);

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { width: windowWidth } = useGetWindowSize();
  const { openPersonalBoard } = useSelector((state: RootState) => state.uiInfo);
  const dispatch = useDispatch();

  return (
    <div
      className={cx(
        "sign-in-wrap",
        openPersonalBoard && windowWidth < 768 && "open-topbar"
      )}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(triggerOpenPersonalBoard(false));
      }}
    >
      <div
        className={cx(
          "top",
          openPersonalBoard && windowWidth < 768 && "open-topbar"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={cx("top-nologin")}>
          <button
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate("/login");
              dispatch(triggerOpenNavbar(false));
              dispatch(triggerOpenPersonalBoard(false));
            }}
          >
            <Person />
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
