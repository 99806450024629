export const convertDate = (time: Date): string => {
  const [year, month, date] = [
    time.getFullYear(),
    time.getMonth() + 1,
    time.getDate() + 1,
  ];
  return year + "/" + month + "/" + date;
};

export const checkDateRangeMoreThanOneDayOrNot = (date1: Date, date2: Date) => {
  let milSec = date2.getTime() - date1.getTime();
  let moreThanOneDay = milSec / (1000 * 3600 * 24);
  return moreThanOneDay > 1;
};
