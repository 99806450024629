import React, { useEffect, useTransition } from "react";
import classnames from "classnames/bind";
import { PlayCircleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  showMusicPlayer,
  selectMusic,
} from "redux/musicPlayer/musicPlayerSlice";
import TopContainer from "container/TopContainer";
import { selectArtist } from "redux/users/userSlice";
import {
  fetchMemberList,
  fetchMusicList,
} from "redux/landingPageInfo/landingPageInfoSlice";
import style from "./index.module.css";
import { RootState, useAppDispatch } from "redux/store";
import userDefault from "assets/user_default.jpg";
import { useSelector } from "react-redux";
import {
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import Spinner from "components/Spinner";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

const WavvLandingPage: React.FC = () => {
  const { memberList, musicList } = useSelector(
    (state: RootState) => state.landingPageInfo
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [isPending, startTransition] = useTransition();
  useEffect(() => {
    startTransition(() => {
      appDispatch(fetchMemberList());
      appDispatch(fetchMusicList());
    });
  }, [appDispatch]);
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content")}>
          <h3 className={cx("list-title")}>New Singers</h3>
          <ul className={cx("list")}>
            {isPending && <Spinner />}
            {memberList.map((singer, index) => {
              const { username, fullname, avatar } = singer;
              return (
                <li key={fullname + " " + index}>
                  <div
                    className={cx("composer-item")}
                    onClick={() => {
                      navigate(`/composer/${username}`);
                      dispatch(triggerOpenNavbar(false));
                      dispatch(triggerOpenPersonalBoard(false));
                      dispatch(
                        selectArtist({
                          username,
                          avatar,
                        })
                      );
                    }}
                  >
                    <div>
                      <img src={avatar ? avatar : userDefault} alt="" />
                    </div>
                    <h2>{fullname}</h2>
                  </div>
                </li>
              );
            })}
          </ul>
          <h3 className={cx("list-title")}>New Songs</h3>
          <ul className={cx("list", "list-square")}>
            {isPending && <Spinner />}
            {musicList.map((song, index) => {
              const {
                filename,
                ownerUsername,
                ownerFullname,
                cover,
                avatar,
                url,
                id,
              } = song;
              const songTitle = filename.split(".")[0];
              return (
                <li key={filename + " " + index}>
                  <div
                    onClick={() => {
                      dispatch(showMusicPlayer());
                      dispatch(
                        selectMusic({
                          songTitle: songTitle,
                          username: ownerUsername,
                          songPic: cover,
                          avatar,
                          musicSource: url,
                          musicId: id,
                        })
                      );
                    }}
                  >
                    <PlayCircleFill />
                    <div className={cx("list-square_img-wrap")}>
                      <img src={cover} alt="" />
                    </div>
                    <h2>{songTitle}</h2>
                    <span>{ownerFullname}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </main>
      <MusicPlayer />
    </>
  );
};

export default WavvLandingPage;
