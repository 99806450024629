//計算音樂分秒時間
export const calculateTime = (secs: number): string => {
  if (isNaN(secs)) {
    return `00 : 00`;
  }
  const min = Math.floor(secs / 60);
  const minutes = min < 10 ? `0${min}` : `${min}`;
  const seconds = Math.floor(secs % 60);
  const returnSecs = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes} : ${returnSecs}`;
};
