import React, { useState, ChangeEvent, useRef } from "react";
import style from "./index.module.css";
import { CardImage } from "react-bootstrap-icons";
import classnames from "classnames/bind";
import TopContainer from "container/TopContainer";
import api from "api";
import { RootState } from "redux/store";
import { useSelector, useDispatch } from "react-redux";
import Popup from "components/Popup";
import { useNavigate } from "react-router-dom";
import {
  triggerLoading,
  triggerOpenModal,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { isAxiosError } from "axios";
import CropImageModal, { CropImageRefType } from "components/CropImageModal";
import { getLocalStorage } from "tools/queryLocalStorage";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

export const getMusicFormat = (filename: string) => {
  const formatArr = filename.split(".");
  return formatArr[formatArr.length - 1];
};

const UploadPage: React.FC = () => {
  const [copyrightVal, setCopyrightVal] = useState<number>(0);
  const [openUPMusicMsg, setOpenUPMusicMsg] = useState<boolean>(false);
  const [fileTitle, setFileTitle] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [resTitle, setResTitle] = useState<string>("");
  const [errMsg, setMsg] = useState<string>("");
  const [musicfile, setMusicFile] = useState<Blob>();
  const [imgfile, setImgFile] = useState<string>("");
  const [imgSrc, setImgSrc] = useState("");
  const [imgfileUpload, setImgFileUpload] = useState<Blob>();
  const [openCropModal, setOpenCropModal] = useState<boolean>(false);
  const [format, setFormat] = useState<string>("");

  const actionRef = useRef<() => void>(() => {});
  const cropImgRef = useRef<CropImageRefType>(null);
  const tokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    uiInfo: { openModal },
  } = useSelector((state: RootState) => state);

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: "music" | "img"
  ) => {
    if (e.target.files) {
      const f = e.target.files[0];
      const fileFormate = getMusicFormat(f.name);
      if (type === "music") {
        if (
          fileFormate === "mp3" ||
          fileFormate === "wav" ||
          fileFormate === "wma" ||
          fileFormate === "aiff"
        ) {
          setFileTitle(f.name);
          setFormat(getMusicFormat(f.name));
          setMusicFile(f);
          return;
        }
        setOpenUPMusicMsg(true);
        actionRef.current = () => {
          setOpenUPMusicMsg(false);
        };
        setResTitle("Sorry, but...");
        setMsg("The file format is not right");
        return;
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgSrc(reader.result?.toString() || "");
        });
        reader.readAsDataURL(f);
        setOpenCropModal(true);
      }
    }
  };

  const handleErrorUpload = (message: string) => {
    dispatch(triggerOpenModal(true));
    setOpenUPMusicMsg(true);
    actionRef.current = () => {
      setOpenUPMusicMsg(false);
    };
    setResTitle("Something went wrong!");
    setMsg(message);
  };

  const handleSuccessUpload = ({
    message,
    musicId,
  }: {
    message: string;
    musicId: string;
  }) => {
    dispatch(triggerOpenModal(true));
    setOpenUPMusicMsg(true);
    actionRef.current = () => {
      setOpenUPMusicMsg(false);
      dispatch(triggerOpenNavbar(false));
      dispatch(triggerOpenPersonalBoard(false));
      navigate(`/songDetail/${musicId}`);
    };
    setResTitle("Upload success!");
    setMsg(message);
  };

  const handleUploadMusic = async () => {
    if (!musicfile || !tokenInfo.token) {
      return;
    }
    if (!title) {
      handleErrorUpload("Music Title can't be empty.");
      return;
    }
    if (!musicfile) {
      handleErrorUpload("Music file should not be empty.");
      return;
    }

    if (!desc) {
      handleErrorUpload("Write some description about your work.");
      return;
    }

    if (!imgfileUpload) {
      handleErrorUpload("Add one pic for this music.");
      return;
    }

    try {
      dispatch(triggerLoading(true));
      const res = await api.postUploadMusic({
        file: musicfile,
        title,
        desc,
        ownership: copyrightVal,
        cover: imgfileUpload,
        token: tokenInfo.token,
        format,
      });
      dispatch(triggerLoading(false));
      const {
        message,
        payload: { musicId },
      } = res;
      handleSuccessUpload({ message, musicId: musicId });
    } catch (error) {
      dispatch(triggerLoading(false));
      if (isAxiosError(error)) {
        handleErrorUpload(error.response?.data.message);
      }
      throw error;
    }
  };
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content")}>
          {/* <!-- Main Content Area start --> */}
          <div className={cx("composer-out")}>
            <h1>Upload Your Music</h1>

            <div className={cx("composer-main")}>
              <div className={cx("img-upload-block")}>
                <div className={cx("file-upload-block")}>
                  <label htmlFor="file-upload" className={cx("file-upload-btn")}>
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileChange(e, "music")
                    }
                  />
                  {fileTitle ? (
                    <p style={{ color: "black" }}>{fileTitle}</p>
                  ) : (
                    <p>wav. wma. mpeg. aiff.</p>
                  )}
                </div>
                <label htmlFor="img-upload-input" className={cx("img-upload")}>
                  {imgfile ? (
                    <img id="show-img" src={imgfile} alt=""/>
                  ) : (
                    <>
                      <CardImage />
                      <p>Choose cover image</p>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="img-upload-input"
                  style={{ display: "none" }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e, "img")
                  }
                />
              </div>

              <div className={cx("composer-data")}>
                <h3>Song Title</h3>
                <input
                  type="text"
                  placeholder="Your Song Title..."
                  onChange={(e) => setTitle(e.target.value)}
                />
                <h3>Song Description</h3>
                <textarea
                  name=""
                  id=""
                  placeholder="Description..."
                  onChange={(e) => setDesc(e.target.value)}
                ></textarea>
                <h3>Do you own the rights to the song?</h3>
                <div className={cx("up-copyright")}>
                  <p>What percentage do you own?</p>
                  <input
                    type="range"
                    value={copyrightVal}
                    min="0"
                    max="100"
                    onChange={(e) => setCopyrightVal(Number(e.target.value))}
                    style={{ filter: `hue-rotate(-${copyrightVal}deg)` }}
                  />
                  <h4>{copyrightVal}</h4>
                </div>
              </div>
            </div>

            <button className={cx("composer-send")} onClick={handleUploadMusic}>
              Upload{" "}
            </button>
          </div>
        </div>
        {openUPMusicMsg && openModal && (
          <Popup
            title={resTitle}
            content={errMsg}
            buttonName="Ok"
            action={actionRef.current}
          />
        )}
        {!!imgSrc && openCropModal && (
          <CropImageModal
            closeModal={() => setOpenCropModal(false)}
            imgSrc={imgSrc}
            saveImgfile={(fileBlob: Blob, fileUrl: string) => {
              setImgFileUpload(fileBlob);
              setImgFile(fileUrl);
              setImgSrc("");
            }}
            ref={cropImgRef}
          />
        )}
      </main>
      <MusicPlayer />
    </>
  );
};

export default UploadPage;
