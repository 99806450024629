import React from "react";
import style from "./index.module.css";
import classnames from "classnames/bind";

const cx = classnames.bind(style);

const Spinner: React.FC = () => {
  return <div className={cx("spinner")}></div>;
};

export default Spinner;
