import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetSingleMusicResonseType } from "api/Musics/queryMusicInfo";
import { PublishMusicParameters } from "api/Musics/publishMusic";
import {
  isPendingAction,
  isRejectedAction,
  isFulfilledAction,
} from "redux/type";
import { triggerLoading } from "redux/modalLoading/uiSlice";
import api from "api";
import { fetchMusicListForSingleUser } from "redux/users/userSlice";
import { UpdateMusicInfoParameters } from "api/Musics/updateMusicInfo";

type InitialState = {
  loading: boolean;
  musicId: string;
  songTitle: string;
  desc: string;
  songPic: string;
  username: string;
  userFullname: string;
  extension: string;
  filename: string;
  avatar: string;
  createdAt: string;
  published: boolean;
  musicSource: string;
  isOwner: boolean;
  message: string;
  apiResult: string;
  musicExist: boolean;
};
//0229ce3e9b897980d18dd4148ec9f2d6
const initialState: InitialState = {
  loading: false,
  musicId: "",
  songTitle: "",
  desc: "",
  songPic: "",
  username: "",
  avatar: "",
  createdAt: "",
  userFullname: "",
  musicSource: "",
  published: false,
  isOwner: false,
  message: "",
  extension: "",
  filename: "",
  apiResult: "",
  musicExist: false,
};

interface FetchMusicByMusicIdParmsType {
  musicId: string;
  token: string;
}

export const fetchMusicByMusicId = createAsyncThunk(
  "songDetail/fetchMusicByMusicId",
  async (params: FetchMusicByMusicIdParmsType, thunkApi) => {
    const { getSingleMusicInfo, getMusicApiResult, getMusicNotExist } =
      songDetailSlice.actions;
    try {
      thunkApi.dispatch(triggerLoading(true));
      const res = await api.querySingleMusicInfo(params);
      const {
        message,
        result,
        payload: {
          cover,
          createdAt,
          desc,
          extension,
          filename,
          id,
          isOwner,
          isPublished,
          ownerFullname,
          ownerUsername,
          musicLength,
          playCount,
          title,
          url, // music url
        },
      } = res;
      thunkApi.dispatch(triggerLoading(false));
      thunkApi.dispatch(
        fetchMusicListForSingleUser({
          specificUsername: ownerUsername,
          token: params.token,
        })
      );
      thunkApi.dispatch(
        getSingleMusicInfo({
          cover,
          createdAt,
          desc,
          extension,
          filename,
          id,
          isOwner,
          isPublished,
          ownerFullname,
          ownerUsername,
          title,
          musicLength,
          playCount,
          url,
        })
      );
      thunkApi.dispatch(
        getMusicApiResult({
          message,
          result,
        })
      );
    } catch (error) {
      thunkApi.dispatch(getMusicNotExist());
      thunkApi.dispatch(triggerLoading(false));
      throw error;
    }
  }
);

export const publishMusic = createAsyncThunk(
  "songDetail/publishMusic",
  async (params: PublishMusicParameters, thunkApi) => {
    try {
      const res = await api.publishMusic(params);
      const { message, result } = res;
      const { getMusicApiResult, publishSuccess } = songDetailSlice.actions;
      if (res.status === "0") {
        thunkApi.dispatch(
          getMusicApiResult({
            message,
            result,
          })
        );
        thunkApi.dispatch(publishSuccess());
      } else {
        thunkApi.dispatch(
          getMusicApiResult({
            message,
            result,
          })
        );
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateMusicInfo = createAsyncThunk(
  "songDetail/updateMusicInfo",
  async (params: UpdateMusicInfoParameters, thunkApi) => {
    try {
      const res = await api.updateMusicInfo(params);
      const { message, result } = res;
      if (res.status === "0") {
        thunkApi.dispatch(
          fetchMusicByMusicId({ musicId: params.musicId, token: params.token })
        );
      }
    } catch (error) {}
  }
);

const songDetailSlice = createSlice({
  name: "songDetail",
  initialState,
  reducers: {
    selectedSong: (state: InitialState, action) => {
      const { username, avatar, songPic, songTitle, published } =
        action.payload;
      state.songTitle = songTitle;
      state.username = username;
      state.avatar = avatar;
      state.songPic = songPic;
      state.published = published;
    },
    checkIsOwner: (state: InitialState, action) => {
      state.isOwner = action.payload;
    },
    getSingleMusicInfo: (
      state: InitialState,
      action: PayloadAction<GetSingleMusicResonseType["payload"]>
    ) => {
      state.songTitle = action.payload.title;
      state.userFullname = action.payload.ownerFullname;
      state.desc = action.payload.desc;
      state.extension = action.payload.extension;
      state.filename = action.payload.filename;
      state.musicId = action.payload.id;
      state.isOwner = action.payload.isOwner;
      state.musicSource = action.payload.url;
      state.username = action.payload.ownerUsername;
      state.songPic = action.payload.cover;
      state.published = action.payload.isPublished;
      state.createdAt = action.payload.createdAt;
      state.musicExist = true;
      state.loading = false;
    },
    getMusicApiResult: (
      state: InitialState,
      action: PayloadAction<{ message: string; result: string }>
    ) => {
      state.message = action.payload.message;
      state.apiResult = action.payload.result;
    },
    publishSuccess: (state: InitialState) => {
      state.published = true;
    },
    getMusicNotExist: (state: InitialState) => {
      state.songTitle = "";
      state.userFullname = "";
      state.desc = "";
      state.extension = "";
      state.filename = "";
      state.musicId = "";
      state.isOwner = false;
      state.musicSource = "";
      state.username = "";
      state.songPic = "";
      state.published = false;
      state.createdAt = "";
      state.loading = false;
      state.musicExist = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state) => {
        state.loading = true;
      })
      .addMatcher(isFulfilledAction, (state, action) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction, (state) => {
        state.loading = false;
      });
  },
});

export default songDetailSlice.reducer;
export const { selectedSong } = songDetailSlice.actions;
