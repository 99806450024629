import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface QueryUserLogoutParameters {
  username: string;
  token: string;
}

export interface LogoutMessageType {
  message: string;
}

export const queryUserLogout = async (
  axios: AxiosInstance,
  params: QueryUserLogoutParameters
): Promise<QueryResponse> => {
  try {
    const { username, token } = params;
    let requestBody = {
      username,
    };
    const response = await axios.post<QueryResponse>(
      `/users/logout`,
      JSON.stringify(requestBody)
    );

    const { data: responseData } = response;
    return responseData;
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
