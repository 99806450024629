import { AxiosError, AxiosInstance } from "axios";
import { MusicDetailType } from "redux/users/userSlice";
import { QueryResponse } from "../type";

export interface QueryMusicListForSpecificUserParameters {
  specificUsername: string;
  token: string;
}

export interface QueryMusicListForSpecificUserResponseType
  extends QueryResponse {
  payload: MusicDetailType[];
}

export const queryMusicListForSpecificUserInfo = async (
  axios: AxiosInstance,
  params: QueryMusicListForSpecificUserParameters
): Promise<QueryMusicListForSpecificUserResponseType> => {
  try {
    const { specificUsername, token } = params;
    const response = await axios.get<QueryMusicListForSpecificUserResponseType>(
      `/music/list/${specificUsername}`
    );

    const {
      data: { message, payload, status },
    } = response;
    if (status === "0") {
      return { message, payload, status, result: "success" };
    } else {
      return { message, payload, status, result: "failed" };
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: [],
        result: "failed",
      };
    }
    throw error;
  }
};
