import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface SendTOTPCodeParameters {
  username: string;
}

export const sendTOTPCode = async (
  axios: AxiosInstance,
  params: SendTOTPCodeParameters
) => {
  try {
    const { username } = params;
    const requestBody = {
      username,
    };
    const response = await axios.post<QueryResponse>(
      `/users/totp`,
      JSON.stringify(requestBody)
    );
    const {
      data: { message, status },
    } = response;
    return { message, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        result: "failed",
        message: error?.response?.data.message,
        status: error?.response?.data.status,
      };
    }
    throw error;
  }
};
