import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface DeleteSingleMusicParameters {
  musicId: string;
  token: string;
}

export interface DeleteSingleMusicMessageType {
  message: string;
}

export const deleteSingleMusic = async (
  axios: AxiosInstance,
  params: DeleteSingleMusicParameters
): Promise<QueryResponse> => {
  try {
    const { musicId, token } = params;
    const response = await axios.delete<QueryResponse>(`/music/${musicId}`);

    const { data: responseData } = response;
    return responseData;
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
