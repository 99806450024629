import { AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface ResetPasswordWithResetTokenParameters {
  resetToken: string;
  newPassword: string;
  confirmPassword: string;
}

const resetPasswordWithResetToken = async (
  axios: AxiosInstance,
  params: ResetPasswordWithResetTokenParameters
): Promise<QueryResponse> => {
  try {
    const { resetToken, newPassword, confirmPassword } = params;
    const config = {
      reset_token: resetToken,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    const response = await axios.post<QueryResponse>(`/users/reset`, config);
    const {
      data: { message, status },
    } = response;
    return { message, status, result: "success" };
  } catch (error) {
    throw error;
  }
};
export default resetPasswordWithResetToken;
