import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface UpdateMusicCountParameters {
  musicId: string;
}

export interface UpdateMusicCountMessageType {
  message: string;
}

export const updateMusicCount = async (
  axios: AxiosInstance,
  params: UpdateMusicCountParameters
): Promise<QueryResponse> => {
  try {
    const { musicId } = params;
    const response = await axios<QueryResponse>({
      method: "POST",
      url: `/music/${musicId}/play_cnt`,
    });

    const {
      data: { message, status },
    } = response;
    return { result: "success", message, status };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        result: "failed",
      };
    }
    throw error;
  }
};
