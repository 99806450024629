import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export type MemberListType = {
  avatar: string;
  createdAt: Date;
  fullname: string;
  username: string;
};

export interface QueryNewUserListResponseType extends QueryResponse {
  payload: MemberListType[];
}

export const queryNewUserList = async (
  axios: AxiosInstance
): Promise<QueryNewUserListResponseType> => {
  try {
    const response = await axios.get<QueryNewUserListResponseType>(
      `/users?limit=10&sort=created_at&order=desc`
    );
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: [],
        result: "failed",
      };
    }
    throw error;
  }
};
