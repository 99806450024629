import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "api/type";

export interface UploadMusicParameter {
  file: Blob;
  cover: Blob;
  title: string;
  desc: string;
  ownership: number;
  token: string;
  format: string;
}

export interface UploadMusicResponseType extends QueryResponse {
  payload: {
    musicId: string;
  };
}

export const uploadMusic = async (
  axios: AxiosInstance,
  params: UploadMusicParameter
): Promise<UploadMusicResponseType> => {
  const { file, title, desc, ownership, token, cover, format } = params;
  const formData = new FormData();
  const filename = title + "." + format;
  formData.append("file", file, filename);
  formData.append("title", title);
  formData.append("desc", desc);
  formData.append("ownership", ownership.toString());
  formData.append("cover", cover);

  const config = {
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axios<UploadMusicResponseType>({
      method: "POST",
      url: `/music/upload`,
      data: formData,
      headers: config,
    });
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: { musicId: "" },
        result: "failed",
      };
    }
    throw error;
  }
};
