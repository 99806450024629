import { AxiosError, AxiosInstance } from "axios";
import { MusicDetailType } from "redux/users/userSlice";
import { QueryResponse } from "../type";

export interface QuerySingleMusicParameters {
  musicId: string;
  token: string;
}

export interface GetSingleMusicResonseType extends QueryResponse {
  payload: MusicDetailType;
}

export const querySingleMusicInfo = async (
  axios: AxiosInstance,
  params: QuerySingleMusicParameters
): Promise<GetSingleMusicResonseType> => {
  try {
    const { musicId, token } = params;
    const response = await axios.get<GetSingleMusicResonseType>(
      `/music/${musicId}`
    );

    const {
      data: { message, payload, status },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: {
          cover: "",
          createdAt: "",
          desc: "",
          extension: "",
          filename: "",
          id: "",
          isOwner: false,
          isPublished: false,
          ownerFullname: "",
          ownerUsername: "",
          musicLength: "",
          playCount: "",
          title: "",
          url: "",
        },
        result: "failed",
      };
    }
    throw error;
  }
};
