import { AxiosError, AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface QueryUserInfoParameters {
  username: string;
  token: string;
}

export interface QueryUserInfoResonseType extends QueryResponse {
  payload: {
    avatar: string | null;
    email: string;
    desc: string;
    fullname: string;
    isOwner: boolean;
    musicCount: number;
    username: string;
  };
}

export const queryUserInfo = async (
  axios: AxiosInstance,
  params: QueryUserInfoParameters
): Promise<QueryUserInfoResonseType> => {
  try {
    const { username } = params;
    const response = await axios.get<QueryUserInfoResonseType>(
      `/users/userinfo/${username}`
    );

    const { data: responseData } = response;
    return responseData;
  } catch (error) {
    if (error instanceof AxiosError) {
      const { status, message } = error.response?.data;
      return {
        status,
        message,
        payload: {
          avatar: null,
          email: "",
          desc: "",
          fullname: "",
          isOwner: false,
          musicCount: 0,
          username: "",
        },
        result: "failed",
      };
    }
    throw error;
  }
};
