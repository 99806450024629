import { AjaxData } from "api/type";
import _ from "lodash";

export function camelCaselize<T = AjaxData>(data: unknown | unknown[]): T {
  let returnValue: unknown = data;
  if (Array.isArray(data)) {
    returnValue = data.map(camelCaselize);
  } else if (typeof data === "object" && data !== null) {
    returnValue = _.entries(data).reduce<AjaxData>((obj, [key, value]) => {
      const camelKey = _.camelCase(key);
      obj[camelKey] = camelCaselize(value);
      return obj;
    }, {});
  }
  return returnValue as T;
}
