import React from "react";
import classnames from "classnames/bind";
import loading from "assets/loading_icon.gif";
import style from "./index.module.css";

const cx = classnames.bind(style);
const Loading: React.FC = () => {
  return (
    <div className={cx("loading")}>
      <img src={loading} alt="loading" />
    </div>
  );
};

export default Loading;
