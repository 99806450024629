import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import style from "./index.module.css";
import {
  ClockFill,
  PlayCircleFill,
  HeartFill,
  PlayCircle,
  Backspace,
  Send,
  Pencil,
  Camera,
  Save,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  showMusicPlayer,
  selectMusic,
} from "redux/musicPlayer/musicPlayerSlice";
import {
  fetchMusicByMusicId,
  publishMusic,
} from "redux/songDetail/songDetailSlice";
import { RootState } from "redux/store";
import Wavv from "assets/wavv_team.jpg";
import Head1 from "assets/head1.jpg";
import Head2 from "assets/head2.jpg";
import Head3 from "assets/head3.jpg";
import Head6 from "assets/head6.jpg";
import Top7 from "assets/top7.jpg";
import { useNavigate } from "react-router-dom";
import TopContainer from "container/TopContainer";
import { useAppDispatch } from "redux/store";
import { convertDate } from "tools/getDate";
import { useParams } from "react-router-dom";
import Popup from "components/Popup";
import classnames from "classnames/bind";
import { fetchMusicListForSingleUser } from "redux/users/userSlice";
import api from "api";
import { getMusicFormat } from "container/UploadMusicPage";
import { QueryResponse } from "api/type";
import {
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} from "redux/modalLoading/uiSlice";
import { getLocalStorage } from "tools/queryLocalStorage";
import CropImageModal, { CropImageRefType } from "components/CropImageModal";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

type UpdateMusicInfoRefType = () => Promise<QueryResponse | undefined>;

const SongDetailPage: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [errMsgTitle, setErrorMsgTitle] = useState<string>("");
  const [openSongDetailModal, setOpenSongDetailModal] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { musicid } = useParams();
  const userTokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
  const saveEditMusicInfo =
    useRef<() => Promise<QueryResponse | undefined>>(null);
  let {
    songDetail: {
      songTitle,
      desc,
      songPic,
      username,
      published,
      musicId,
      musicSource,
      avatar,
      createdAt,
      apiResult,
      musicExist,
      isOwner,
    },
    artistDetail: { musicList },
  } = useSelector((state: RootState) => state);

  const createTime = new Date(createdAt);
  username = username.replace("@", "");

  useEffect(() => {
    if (musicid) {
      appDispatch(
        fetchMusicByMusicId({
          musicId: musicid,
          token:
            userTokenInfo && userTokenInfo.token ? userTokenInfo.token : "",
        })
      );
    }
  }, [ musicid, appDispatch, userTokenInfo]);

  useEffect(() => {
    if (published) {
      appDispatch(
        fetchMusicListForSingleUser({
          specificUsername: username,
          token:
            userTokenInfo && userTokenInfo.token ? userTokenInfo.token : "",
        })
      );
      //取得music list和comment list
    }
  }, [published]);
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content")}>
          <div className={cx("song-detail_out")}>
            {edit && isOwner ? (
              <SongDetailEditMusicInfo
                songPic={songPic}
                songTitle={songTitle}
                published={published}
                desc={desc}
                username={username}
                musicSource={musicSource}
                avatar={avatar}
                createdAt={convertDate(createTime)}
                musicExist={musicExist}
                musicId={musicId}
                closeEdit={() => setEdit(false)}
                isOwner={isOwner}
                ref={saveEditMusicInfo}
              />
            ) : (
              <SongDetailMusicInfo
                songPic={songPic}
                songTitle={songTitle}
                published={published}
                desc={desc}
                username={username}
                musicSource={musicSource}
                avatar={avatar}
                createdAt={convertDate(createTime)}
                musicExist={musicExist}
                musicId={musicId}
                isOwner={isOwner}
                edit={() => setEdit(true)}
              />
            )}
            {published ? (
              <>
                {/* <!-- More --> */}
                <div className={cx("song-detail_more")}>
                  <h3>More of {username ? username : "Nada Reyes"}</h3>
                  <ul>
                    {musicList
                      .filter((music) => music.isPublished)
                      .map((music) => {
                        const { cover, title, ownerUsername, url, id } = music;
                        return (
                          <li key={id}>
                            <div
                              className={cx("song-detail_more_wrap")}
                              onClick={() => {
                                dispatch(showMusicPlayer());
                                dispatch(
                                  selectMusic({
                                    songTitle: title,
                                    username: ownerUsername,
                                    songPic: cover,
                                    avatar: "",
                                    musicSource: url,
                                    musicId: id,
                                  })
                                );
                              }}
                            >
                              <PlayCircleFill />
                              <div className={cx("song-detail_more_img")}>
                                <img src={cover ? cover : Top7} alt="" />
                              </div>
                              <h2>{title}</h2>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- Comments --> */}
                <div className={cx("song-detail_comments")}>
                  <h3>Comments</h3>
                  <div className={cx("comments-text")}>
                    <div className={cx("comments-head")}>
                      <img src={Head6} alt="" />
                    </div>
                    <h4>Shaan Merrill</h4>
                    <form action="">
                      <div
                        contentEditable={true}
                        data-text="Your Comment..."
                      ></div>
                      <button>Send</button>
                    </form>
                  </div>
                  <ul>
                    <li>
                      <div className={cx("comments-head")}>
                        <img src={Head1} alt="" />
                      </div>
                      <h4>Margot Correa</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Fugiat pariatur laborum hic nam magnam natus quod, et
                        nostrum similique ex reprehenderit, quaerat facilis at
                        quis voluptatibus itaque iste eos fuga!
                      </p>
                      <span>3 hours ago</span>
                    </li>
                    <li>
                      <div className={cx("comments-head")}>
                        <img src={Head2} alt="" />
                      </div>
                      <h4>Fahima Bateman</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Nam, veritatis!
                      </p>
                      <span>3 hours ago</span>
                    </li>
                    <li>
                      <div className={cx("comments-head")}>
                        <img src={Head3} alt="" />
                      </div>
                      <h4>Abu Rosa</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                        Quos ducimus non obcaecati veritatis.
                      </p>
                      <span>3 hours ago</span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              musicExist &&
              isOwner && (
                <ul className={cx("publish-button-list")}>
                  <li>
                    <button
                      onClick={() => {
                        navigate("/uploadmusic");
                        dispatch(triggerOpenNavbar(false));
                        dispatch(triggerOpenPersonalBoard(false));
                      }}
                    >
                      <Backspace />
                      Cancel
                    </button>
                  </li>
                  {edit ? (
                    <li>
                      <button
                        onClick={async () => {
                          setEdit(false);
                          if (saveEditMusicInfo.current) {
                            const res = await saveEditMusicInfo.current();
                            setOpenSongDetailModal(true);
                            if (res) {
                              setErrorMsgTitle("Update Music Result");
                              setOpenSongDetailModal(true);
                              setErrorMsg(res.message);
                            }
                          }
                        }}
                      >
                        <Save />
                        Save
                      </button>
                    </li>
                  ) : (
                    <li>
                      <button onClick={() => setEdit(true)}>
                        <Pencil />
                        Edit
                      </button>
                    </li>
                  )}
                  {!edit && (
                    <li className={cx("song-detail_publish-list")}>
                      <button
                        className={cx("song-detail_publish")}
                        onClick={() => {
                          if (
                            userTokenInfo &&
                            userTokenInfo.token &&
                            username &&
                            musicId
                          ) {
                            appDispatch(
                              publishMusic({
                                username,
                                musicId,
                                token:
                                  userTokenInfo && userTokenInfo.token
                                    ? userTokenInfo.token
                                    : "",
                              })
                            );
                            if (apiResult === "success") {
                              navigate(`/songDetail/${musicId}`);
                              dispatch(triggerOpenNavbar(false));
                              dispatch(triggerOpenPersonalBoard(false));
                            } else {
                              setErrorMsgTitle("Sorry,but...");
                              setErrorMsg("Publish failed");
                              setOpenSongDetailModal(true);
                            }
                          }
                        }}
                      >
                        <Send />
                        Publish
                      </button>
                    </li>
                  )}
                </ul>
              )
            )}
          </div>
          {/* <!-- Song Detail Out end --> */}
        </div>
        {openSongDetailModal && (
          <Popup
            title={errMsgTitle}
            content={errorMsg}
            buttonName="OK"
            action={async () => {
              setOpenSongDetailModal(false);
            }}
          />
        )}
      </main>
      <MusicPlayer />
    </>
  );
};

interface BasicMusicInfoType {
  songPic: string;
  songTitle: string;
  published: boolean;
  desc: string;
  username: string;
  musicSource: string;
  createdAt: string;
  avatar: string;
  musicExist: boolean;
  musicId: string;
  isOwner: boolean;
}
interface MusicInfoType extends BasicMusicInfoType {
  edit(): void;
}
interface EditMusicInfoType extends BasicMusicInfoType {
  closeEdit(): void;
}

const SongDetailMusicInfo: React.FC<MusicInfoType> = ({
  songPic,
  songTitle,
  published,
  desc,
  avatar,
  username,
  createdAt,
  musicSource,
  musicExist,
  musicId,
  isOwner,
  edit,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className={cx("song-detail_head")}>
        <div className={cx("song-cover-wrap")}>
          <img src={songPic ? songPic : Top7} alt="" />
        </div>
        <div className={cx("song-detail_singer")}>
          <h1>{songTitle ? songTitle : "Music Not Exist"}</h1>
          <p>{desc}</p>
          <ul className={cx("song-detail_info")}>
            <li>
              <div className={cx("song-composer-info")}>
                <img src={avatar ? avatar : Wavv} alt="" />
                {username ? username : "Wavv Musician"}
              </div>
            </li>
            {published ? (
              <>
                <li>
                  <ClockFill />
                  {createdAt ? createdAt : "2022/08/12"}
                </li>
                <li>
                  <PlayCircleFill />
                  4566 Plays
                </li>
                <li>
                  <HeartFill />
                  328 Favorites
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
          <ul className={cx("song-detail_btns")}>
            {musicExist ? (
              <>
                <li>
                  <button
                    className={cx("song-detail_play")}
                    onClick={() => {
                      dispatch(showMusicPlayer());
                      dispatch(
                        selectMusic({
                          songTitle,
                          username,
                          songPic,
                          avatar,
                          musicSource,
                          musicId,
                        })
                      );
                    }}
                  >
                    <PlayCircle />
                    Play
                  </button>
                </li>
                {published && (
                  <>
                    {/* <li>
                      <button>
                        <Share />
                        Share
                      </button>
                    </li>
                    <li>
                      <button>
                        <Heart />
                        Favorite
                      </button>
                    </li> */}
                    {isOwner && (
                      <li>
                        <button onClick={() => edit()}>
                          <Pencil />
                          Edit
                        </button>
                      </li>
                    )}
                  </>
                )}
              </>
            ) : (
              <div>Music Not Exist</div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

const SongDetailEditMusicInfo = forwardRef<
  UpdateMusicInfoRefType,
  EditMusicInfoType
>(
  (
    {
      songPic,
      songTitle,
      published,
      desc,
      avatar,
      username,
      createdAt,
      musicSource,
      musicExist,
      musicId,
      closeEdit,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [editTitle, setEditTitle] = useState<string>(songTitle);
    const [editDesc, setEditDesc] = useState<string>(desc);
    const [uploadUserImg, setUploadUserImg] = useState<Blob>();
    const [, setOpenUPMusicMsg] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string>(songPic);
    const [fileTitle, setFileTitle] = useState<string>("");
    const actionRef = useRef<() => void>(() => {});
    const [, setFormat] = useState<string>("");
    const [musicfile, setMusicFile] = useState<Blob>();
    const [imgSrc, setImgSrc] = useState<string>("");
    const [, setResTitle] = useState<string>("");
    const [, setMsg] = useState<string>("");
    const userTokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");
    const [openCropModal, setOpenCropModal] = useState<boolean>(false);
    const cropImgRef = useRef<CropImageRefType>(null);
    const appDispatch = useAppDispatch();

    const updateMusicInfo = async () => {
      try {
        if (userTokenInfo && userTokenInfo.token) {
          const res = await api.updateMusicInfo({
            musicId,
            file: musicfile,
            title: editTitle,
            desc: editDesc,
            cover: uploadUserImg,
            token: userTokenInfo.token,
          });
          appDispatch(
            fetchMusicByMusicId({ musicId, token: userTokenInfo.token })
          );
          return res;
        }
      } catch (error) {
        throw error;
      }
    };

    useImperativeHandle(ref, () => updateMusicInfo);

    const handleFileChange = (
      e: ChangeEvent<HTMLInputElement>,
      type: "music" | "img"
    ) => {
      if (e.target.files) {
        const f = e.target.files[0];
        const fileFormate = getMusicFormat(f.name);
        if (type === "music") {
          if (
            fileFormate === "mp3" ||
            fileFormate === "wav" ||
            fileFormate === "wma" ||
            fileFormate === "aiff"
          ) {
            setFileTitle(f.name);
            setFormat(getMusicFormat(f.name));
            setMusicFile(f);
            return;
          }
          setOpenUPMusicMsg(true);
          actionRef.current = () => {
            setOpenUPMusicMsg(false);
          };
          setResTitle("Sorry, but...");
          setMsg("The file format is not right");
          return;
        } else {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setImgSrc(reader.result?.toString() || "");
          });
          reader.readAsDataURL(f);
          setOpenCropModal(true);
        }
      }
    };
    return (
      <>
        <div className={cx("song-detail_head")}>
          <div className={cx("song-cover-wrap")}>
            <label htmlFor="coverImage" className={cx("cover-img_editmask")}>
              <Camera />
            </label>
            <input
              type="file"
              id="coverImage"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFileChange(e, "img")
              }
            />
            <img src={imgUrl ? imgUrl : Top7} alt="" />
          </div>
          <div className={cx("song-detail_singer", "edit")}>
            <input
              type="text"
              className={cx("song-title_input")}
              placeholder="Your name..."
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
            />
            <textarea
              className={cx("song-bio_input")}
              placeholder="Your bio..."
              value={editDesc}
              onChange={(e) => setEditDesc(e.target.value)}
            />
            <ul className={cx("song-detail_info")}>
              <li>
                <div className={cx("song-composer-info")}>
                  <img src={avatar ? avatar : Wavv} alt="" />
                  {username ? username : "Wavv Musician"}
                </div>
              </li>
              {published ? (
                <>
                  <li>
                    <ClockFill />
                    {createdAt ? createdAt : "2022/08/12"}
                  </li>
                  <li>
                    <PlayCircleFill />
                    4566 Plays
                  </li>
                  <li>
                    <HeartFill />
                    328 Favorites
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <ul className={cx("song-detail_btns")}>
              {musicExist ? (
                <>
                  <li>
                    <button
                      className={cx("song-detail_play")}
                      onClick={() => {
                        dispatch(showMusicPlayer());
                        dispatch(
                          selectMusic({
                            songTitle,
                            username,
                            songPic,
                            avatar,
                            musicSource,
                            musicId,
                          })
                        );
                      }}
                    >
                      <PlayCircle />
                      Play
                    </button>
                  </li>
                  {/* {published ? (
                    <>
                      <li>
                        <button>
                          <Share />
                          Share
                        </button>
                      </li>
                      <li>
                        <button>
                          <Heart />
                          Favorite
                        </button>
                      </li>
                    </>
                  ) : (
                    ""
                  )} */}
                  {published && (
                    <li>
                      <button
                        onClick={() => {
                          updateMusicInfo();
                          closeEdit();
                        }}
                      >
                        <Save />
                        Save
                      </button>
                    </li>
                  )}
                  <li>
                    <div className={cx("upload-new-music-file")}>
                      <label
                        htmlFor="file-upload"
                        className={cx("file-upload-btn")}
                      >
                        Update Music File
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleFileChange(e, "music")
                        }
                      />
                      {fileTitle ? (
                        <p className={cx("music-file-title-col")}>
                          {fileTitle}
                        </p>
                      ) : (
                        <p className={cx("music-format-col")}>
                          wav. wma. mpeg. aiff.
                        </p>
                      )}
                    </div>
                  </li>
                </>
              ) : (
                <div>Music Not Exist</div>
              )}
            </ul>
          </div>
        </div>
        {!!imgSrc && openCropModal && (
          <CropImageModal
            closeModal={() => setOpenCropModal(false)}
            imgSrc={imgSrc}
            saveImgfile={(fileBlob: Blob, fileUrl: string) => {
              setUploadUserImg(fileBlob);
              setImgUrl(fileUrl);
            }}
            ref={cropImgRef}
          />
        )}
      </>
    );
  }
);

export default SongDetailPage;
