import React from "react";
import { Helmet } from "react-helmet";
import facebook from "assets/facebook.png";
export interface SEOType {
  title: string;
  description: string;
  url: string;
}

const SEO: React.FC<SEOType> = ({ title, description, url }) => {
  return (
    <Helmet>
      <meta name="title" content={title} />
      <meta name="title" content={url} />
      <meta name="description" content={description} />
      <meta property="og:title" content="Wavv"/>
      <meta property="og:description" content="web3 Music Streaming Platform"/>
      <meta property="og:image" content="http://musasi.vaserver.com/wavv_landing/img/facebook.png"/>
      <meta name="og:title" content="Wavv"/>
      <meta name="og:description" content="web3 Music Streaming Platform"/>
      <meta name="og:image" content="http://musasi.vaserver.com/wavv_landing/img/facebook.png"/>
    </Helmet>
  );
};

export default SEO;
