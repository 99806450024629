export default function converURIToBlob(dataURI: string) {
  //   let [dataContentType, dataBase64] = dataURI.split(",");
  //   let data = atob(dataBase64);
  //   let dataArray = new Uint8Array(data.length);
  //   for (var i = 0; i < data.length; i++) {
  //     dataArray[i] = data.charCodeAt(i);
  //   }
  //   return new Blob([dataArray], { type: dataContentType });
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  //url to blob
  const blobFile: any = new Blob([ia], { type: mimeString });
  //blob to file
  let croppedFile = new File([blobFile], "croppedImg.jpg", {
    lastModified: new Date().getTime(),
    type: blobFile.type,
  });
  return croppedFile;
}
