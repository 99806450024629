export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password: string) {
  const re = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W).{7,33}$/;
  return re.test(String(password).toLowerCase());
}

export function validateUserName(name: string) {
  return name.length > 3 && name.length < 33;
}

//check file signature
export function checkMusicFormate(val: string) {
  let type = "";
  switch (val) {
    case "52494646":
      type = "wavv";
      break;
    case "fffb":
    case "fff3":
    case "fff2":
    case "4944334":
      type = "audio/mp3";
      break;
    case "3026b275":
    case "a6d900aa":
      type = "audio/wma";
      break;
    case "000001b3":
    case "000001ba":
      type = "audio/mpeg";
      break;
    case "464f524d":
      type = "audio/aiff";
      break;
    default:
      type = "others";
  }
  return type;
}

export function convertFormatToString(file: Blob) {
  const file_type = file.type; //副檔名的format
  let reader = new FileReader();
  let type = "";
  reader.onloadend = function (e) {
    if (e.target && e.target.result) {
      let result = new Uint8Array(e.target.result as ArrayBuffer);
      let header = "";
      for (var i = 0; i < result.length; i++) {
        header += result[i].toString(16);
      }
      type = checkMusicFormate(header); //real format
    }
  };
  if (type === "others" || type === "") {
    return false;
  } else {
    return true;
  }
}
