import React from "react";
import SignIn from "components/SignIn";
import Upload from "components/Upload";
import { getLocalStorage } from "tools/queryLocalStorage";

const TopContainer: React.FC = () => {
  const userTokenInfo = getLocalStorage("LOGIN_USER_TOKEN_INFO");

  return <>{userTokenInfo?.token ? <Upload /> : <SignIn />}</>;
};

export default TopContainer;
