import React, { useState } from "react";
import style from "./index.module.css";
import classnames from "classnames/bind";
import TopContainer from "container/TopContainer";
import { validateEmail } from "tools/validate";
import api from "api";
import { triggerLoading } from "redux/modalLoading/uiSlice";
import { isAxiosError } from "axios";
import { useDispatch } from "react-redux";
import { setTmpUserId } from "redux/registerUserInfo/registerSlice";
import { useNavigate } from "react-router-dom";
import Navbar from "container/Navbar";
import MusicPlayer from "container/MusicPlayer";

const cx = classnames.bind(style);

const ForgetPasswordPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <main>
        <TopContainer />
        <div className={cx("content", "forget-password-wrap")}>
          <div className={cx("forget-password")}>
            <div>
              <h2>Forgot Password</h2>
              <div className={cx("input-column")}>
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  className={cx("submit")}
                  onClick={async () => {
                    setErrorMsg("");
                    if (email.length === 0) {
                      setErrorMsg("Email should not be empty");
                      return;
                    }
                    if (!validateEmail(email)) {
                      setErrorMsg("Email formate is not correct!");
                      return;
                    }
                    try {
                      dispatch(triggerLoading(true));
                      const res = await api.forgetPassword({ email });
                      const {
                        payload: { tmpUserId },
                      } = res;
                      dispatch(triggerLoading(false));
                      dispatch(setTmpUserId(tmpUserId));
                      navigate("/otpconfirm", {
                        state: { type: "forget" },
                      });
                    } catch (error) {
                      dispatch(triggerLoading(false));
                      if (isAxiosError(error)) {
                        setErrorMsg(error.response?.data.message);
                      }
                      throw error;
                    }
                  }}
                >
                  Submit
                </button>
              </div>
              <div className={cx("hint")}>{errorMsg}</div>
              <p>
                Please insert your email, we will send identifying code to you, so
                that you can setting new Password.
              </p>
            </div>
          </div>
        </div>
      </main>
      <MusicPlayer />
    </>
  );
};

export default ForgetPasswordPage;
