import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
type songDetailType = {
  songTitle: string;
  username: string;
  avatar: string;
  songPic: string;
  musicSource: string;
  musicId: string;
};

type InitialState = {
  showPlayer: boolean;
  songDetail: songDetailType;
};

const initialState: InitialState = {
  showPlayer: false,
  songDetail: {
    songTitle: "",
    username: "",
    avatar: "",
    songPic: "",
    musicSource: "",
    musicId: "",
  },
};
// 5746b12715a38c09be9b07ea4b556096
export const addMusicPlayCount = createAsyncThunk(
  "music/addMusicPlayCount",
  async (musicId: string) => {
    try {
      const res = await api.updateMusicCount({ musicId });
    } catch (error) {
      throw error;
    }
  }
);

const musicPlayerSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    showMusicPlayer: (state: InitialState) => {
      state.showPlayer = true;
    },
    closeMusicPlayer: (state) => {
      state.showPlayer = false;
    },
    selectMusic: (
      state: InitialState,
      action: PayloadAction<songDetailType>
    ) => {
      state.songDetail = action.payload;
    },
  },
});

export default musicPlayerSlice.reducer;
export const { showMusicPlayer, closeMusicPlayer, selectMusic } =
  musicPlayerSlice.actions;
