import { AxiosInstance } from "axios";
import { QueryResponse } from "../type";

export interface ForgetPasswordOTPCodeParameters {
  otpCode: string;
  tmpUserId: string;
}

export interface ForgetPasswordOTPCodeResponseType extends QueryResponse {
  payload: {
    resetToken: string;
  };
}

const verifyForgetPasswordOTPCode = async (
  axios: AxiosInstance,
  params: ForgetPasswordOTPCodeParameters
): Promise<ForgetPasswordOTPCodeResponseType> => {
  try {
    const { otpCode, tmpUserId } = params;
    const response = await axios.get<ForgetPasswordOTPCodeResponseType>(
      `/users/forget/${tmpUserId}/${otpCode}`
    );
    const {
      data: { message, status, payload },
    } = response;
    return { message, payload, status, result: "success" };
  } catch (error) {
    throw error;
  }
};
export default verifyForgetPasswordOTPCode;
