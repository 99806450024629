import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  openModal: boolean;
  triggerLoading: boolean;
  openNavbar: boolean;
  openPersonalBoard: boolean;
};

const initialState: InitialState = {
  openModal: false,
  triggerLoading: false,
  openNavbar: false,
  openPersonalBoard: false,
};

const uiDetailSlice = createSlice({
  name: "uiDetail",
  initialState,
  reducers: {
    triggerOpenModal: (state: InitialState, action: PayloadAction<boolean>) => {
      state.openModal = action.payload;
    },
    triggerLoading: (state: InitialState, action: PayloadAction<boolean>) => {
      state.triggerLoading = action.payload;
    },
    triggerOpenNavbar: (
      state: InitialState,
      action: PayloadAction<boolean>
    ) => {
      state.openNavbar = action.payload;
    },
    triggerOpenPersonalBoard: (
      state: InitialState,
      action: PayloadAction<boolean>
    ) => {
      state.openPersonalBoard = action.payload;
    },
  },
});

export default uiDetailSlice.reducer;
export const {
  triggerOpenModal,
  triggerLoading,
  triggerOpenNavbar,
  triggerOpenPersonalBoard,
} = uiDetailSlice.actions;
